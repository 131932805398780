import React from "react";
import { connect } from "react-redux";

const MediaCloudCarousel = ({}) => {
    return (
        <>
            <div className="swiper flq-swiper-effect-touch container-fluid mt-7 mb-3" 
                data-sr 
                data-sr-duration={1500} data-gap={20} data-buttons={true} data-autoplay={2000} data-loop={true} 
                data-free-mode={true} data-speed={1000} data-touch-ratio={0.6} data-breakpoints={{320:3,500:4,848:5,1072:6,1280:8,1480:10}}>
                <div className="swiper-container">
                    <div className="swiper-wrapper">
                        <div className="swiper-slide">
                            <div className="card flq-card-image flq-card-image-instagram">
                                <a href="#" className="card-image">
                                    <span className="flq-image flq-responsive
  flq-responsive-1x1">
                                        <img src="assets/images/background-ticker/1.jpg" alt="" />
                                    </span>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.22506 0.450134C3.03566 0.450134 0.450134 3.03566 0.450134 6.22506V17.7749C0.450134 20.9643 3.03566 23.5498 6.22506 23.5498H17.7749C20.9643 23.5498 23.5498 20.9643 23.5498 17.7749V6.22506C23.5498 3.03566 20.9643 0.450134 17.7749 0.450134H6.22506ZM5.5834 12C5.5834 15.5437 8.45621 18.4166 12 18.4166C15.5437 18.4166 18.4166 15.5437 18.4166 12C18.4166 8.45621 15.5437 5.5834 12 5.5834C8.45621 5.5834 5.5834 8.45621 5.5834 12ZM12 15.8499C14.1263 15.8499 15.8499 14.1263 15.8499 12C15.8499 9.87365 14.1263 8.15003 12 8.15003C9.87366 8.15003 8.15004 9.87365 8.15004 12C8.15004 14.1263 9.87366 15.8499 12 15.8499ZM19.6999 4.30008C19.6999 5.00883 19.1253 5.5834 18.4166 5.5834C17.7078 5.5834 17.1333 5.00883 17.1333 4.30008C17.1333 3.59133 17.7078 3.01677 18.4166 3.01677C19.1253 3.01677 19.6999 3.59133 19.6999 4.30008Z" fill="currentColor" />
                                    </svg>
                                </a>
                                <div className="card-body">
                                </div>
                            </div>
                        </div>
                        <div className="swiper-slide">
                            <div className="card flq-card-image flq-card-image-instagram">
                                <a href="#" className="card-image">
                                    <span className="flq-image flq-responsive
  flq-responsive-1x1">
                                        <img src="assets/images/background-ticker/2.jpg" alt="" />
                                    </span>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.22506 0.450134C3.03566 0.450134 0.450134 3.03566 0.450134 6.22506V17.7749C0.450134 20.9643 3.03566 23.5498 6.22506 23.5498H17.7749C20.9643 23.5498 23.5498 20.9643 23.5498 17.7749V6.22506C23.5498 3.03566 20.9643 0.450134 17.7749 0.450134H6.22506ZM5.5834 12C5.5834 15.5437 8.45621 18.4166 12 18.4166C15.5437 18.4166 18.4166 15.5437 18.4166 12C18.4166 8.45621 15.5437 5.5834 12 5.5834C8.45621 5.5834 5.5834 8.45621 5.5834 12ZM12 15.8499C14.1263 15.8499 15.8499 14.1263 15.8499 12C15.8499 9.87365 14.1263 8.15003 12 8.15003C9.87366 8.15003 8.15004 9.87365 8.15004 12C8.15004 14.1263 9.87366 15.8499 12 15.8499ZM19.6999 4.30008C19.6999 5.00883 19.1253 5.5834 18.4166 5.5834C17.7078 5.5834 17.1333 5.00883 17.1333 4.30008C17.1333 3.59133 17.7078 3.01677 18.4166 3.01677C19.1253 3.01677 19.6999 3.59133 19.6999 4.30008Z" fill="currentColor" />
                                    </svg>
                                </a>
                                <div className="card-body">
                                </div>
                            </div>
                        </div>
                        <div className="swiper-slide">
                            <div className="card flq-card-image flq-card-image-instagram">
                                <a href="#" className="card-image">
                                    <span className="flq-image flq-responsive
  flq-responsive-1x1">
                                        <img src="assets/images/background-ticker/3.jpg" alt="" />
                                    </span>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.22506 0.450134C3.03566 0.450134 0.450134 3.03566 0.450134 6.22506V17.7749C0.450134 20.9643 3.03566 23.5498 6.22506 23.5498H17.7749C20.9643 23.5498 23.5498 20.9643 23.5498 17.7749V6.22506C23.5498 3.03566 20.9643 0.450134 17.7749 0.450134H6.22506ZM5.5834 12C5.5834 15.5437 8.45621 18.4166 12 18.4166C15.5437 18.4166 18.4166 15.5437 18.4166 12C18.4166 8.45621 15.5437 5.5834 12 5.5834C8.45621 5.5834 5.5834 8.45621 5.5834 12ZM12 15.8499C14.1263 15.8499 15.8499 14.1263 15.8499 12C15.8499 9.87365 14.1263 8.15003 12 8.15003C9.87366 8.15003 8.15004 9.87365 8.15004 12C8.15004 14.1263 9.87366 15.8499 12 15.8499ZM19.6999 4.30008C19.6999 5.00883 19.1253 5.5834 18.4166 5.5834C17.7078 5.5834 17.1333 5.00883 17.1333 4.30008C17.1333 3.59133 17.7078 3.01677 18.4166 3.01677C19.1253 3.01677 19.6999 3.59133 19.6999 4.30008Z" fill="currentColor" />
                                    </svg>
                                </a>
                                <div className="card-body">
                                </div>
                            </div>
                        </div>
                        <div className="swiper-slide">
                            <div className="card flq-card-image flq-card-image-instagram">
                                <a href="#" className="card-image">
                                    <span className="flq-image flq-responsive
  flq-responsive-1x1">
                                        <img src="assets/images/background-ticker/4.jpg" alt="" />
                                    </span>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.22506 0.450134C3.03566 0.450134 0.450134 3.03566 0.450134 6.22506V17.7749C0.450134 20.9643 3.03566 23.5498 6.22506 23.5498H17.7749C20.9643 23.5498 23.5498 20.9643 23.5498 17.7749V6.22506C23.5498 3.03566 20.9643 0.450134 17.7749 0.450134H6.22506ZM5.5834 12C5.5834 15.5437 8.45621 18.4166 12 18.4166C15.5437 18.4166 18.4166 15.5437 18.4166 12C18.4166 8.45621 15.5437 5.5834 12 5.5834C8.45621 5.5834 5.5834 8.45621 5.5834 12ZM12 15.8499C14.1263 15.8499 15.8499 14.1263 15.8499 12C15.8499 9.87365 14.1263 8.15003 12 8.15003C9.87366 8.15003 8.15004 9.87365 8.15004 12C8.15004 14.1263 9.87366 15.8499 12 15.8499ZM19.6999 4.30008C19.6999 5.00883 19.1253 5.5834 18.4166 5.5834C17.7078 5.5834 17.1333 5.00883 17.1333 4.30008C17.1333 3.59133 17.7078 3.01677 18.4166 3.01677C19.1253 3.01677 19.6999 3.59133 19.6999 4.30008Z" fill="currentColor" />
                                    </svg>
                                </a>
                                <div className="card-body">
                                </div>
                            </div>
                        </div>
                        <div className="swiper-slide">
                            <div className="card flq-card-image flq-card-image-instagram">
                                <a href="#" className="card-image">
                                    <span className="flq-image flq-responsive
  flq-responsive-1x1">
                                        <img src="assets/images/background-ticker/5.jpg" alt="" />
                                    </span>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.22506 0.450134C3.03566 0.450134 0.450134 3.03566 0.450134 6.22506V17.7749C0.450134 20.9643 3.03566 23.5498 6.22506 23.5498H17.7749C20.9643 23.5498 23.5498 20.9643 23.5498 17.7749V6.22506C23.5498 3.03566 20.9643 0.450134 17.7749 0.450134H6.22506ZM5.5834 12C5.5834 15.5437 8.45621 18.4166 12 18.4166C15.5437 18.4166 18.4166 15.5437 18.4166 12C18.4166 8.45621 15.5437 5.5834 12 5.5834C8.45621 5.5834 5.5834 8.45621 5.5834 12ZM12 15.8499C14.1263 15.8499 15.8499 14.1263 15.8499 12C15.8499 9.87365 14.1263 8.15003 12 8.15003C9.87366 8.15003 8.15004 9.87365 8.15004 12C8.15004 14.1263 9.87366 15.8499 12 15.8499ZM19.6999 4.30008C19.6999 5.00883 19.1253 5.5834 18.4166 5.5834C17.7078 5.5834 17.1333 5.00883 17.1333 4.30008C17.1333 3.59133 17.7078 3.01677 18.4166 3.01677C19.1253 3.01677 19.6999 3.59133 19.6999 4.30008Z" fill="currentColor" />
                                    </svg>
                                </a>
                                <div className="card-body">
                                </div>
                            </div>
                        </div>
                        <div className="swiper-slide">
                            <div className="card flq-card-image flq-card-image-instagram">
                                <a href="#" className="card-image">
                                    <span className="flq-image flq-responsive
  flq-responsive-1x1">
                                        <img src="assets/images/background-ticker/6.jpg" alt="" />
                                    </span>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.22506 0.450134C3.03566 0.450134 0.450134 3.03566 0.450134 6.22506V17.7749C0.450134 20.9643 3.03566 23.5498 6.22506 23.5498H17.7749C20.9643 23.5498 23.5498 20.9643 23.5498 17.7749V6.22506C23.5498 3.03566 20.9643 0.450134 17.7749 0.450134H6.22506ZM5.5834 12C5.5834 15.5437 8.45621 18.4166 12 18.4166C15.5437 18.4166 18.4166 15.5437 18.4166 12C18.4166 8.45621 15.5437 5.5834 12 5.5834C8.45621 5.5834 5.5834 8.45621 5.5834 12ZM12 15.8499C14.1263 15.8499 15.8499 14.1263 15.8499 12C15.8499 9.87365 14.1263 8.15003 12 8.15003C9.87366 8.15003 8.15004 9.87365 8.15004 12C8.15004 14.1263 9.87366 15.8499 12 15.8499ZM19.6999 4.30008C19.6999 5.00883 19.1253 5.5834 18.4166 5.5834C17.7078 5.5834 17.1333 5.00883 17.1333 4.30008C17.1333 3.59133 17.7078 3.01677 18.4166 3.01677C19.1253 3.01677 19.6999 3.59133 19.6999 4.30008Z" fill="currentColor" />
                                    </svg>
                                </a>
                                <div className="card-body">
                                </div>
                            </div>
                        </div>
                        <div className="swiper-slide">
                            <div className="card flq-card-image flq-card-image-instagram">
                                <a href="#" className="card-image">
                                    <span className="flq-image flq-responsive
  flq-responsive-1x1">
                                        <img src="assets/images/background-ticker/7.jpg" alt="" />
                                    </span>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.22506 0.450134C3.03566 0.450134 0.450134 3.03566 0.450134 6.22506V17.7749C0.450134 20.9643 3.03566 23.5498 6.22506 23.5498H17.7749C20.9643 23.5498 23.5498 20.9643 23.5498 17.7749V6.22506C23.5498 3.03566 20.9643 0.450134 17.7749 0.450134H6.22506ZM5.5834 12C5.5834 15.5437 8.45621 18.4166 12 18.4166C15.5437 18.4166 18.4166 15.5437 18.4166 12C18.4166 8.45621 15.5437 5.5834 12 5.5834C8.45621 5.5834 5.5834 8.45621 5.5834 12ZM12 15.8499C14.1263 15.8499 15.8499 14.1263 15.8499 12C15.8499 9.87365 14.1263 8.15003 12 8.15003C9.87366 8.15003 8.15004 9.87365 8.15004 12C8.15004 14.1263 9.87366 15.8499 12 15.8499ZM19.6999 4.30008C19.6999 5.00883 19.1253 5.5834 18.4166 5.5834C17.7078 5.5834 17.1333 5.00883 17.1333 4.30008C17.1333 3.59133 17.7078 3.01677 18.4166 3.01677C19.1253 3.01677 19.6999 3.59133 19.6999 4.30008Z" fill="currentColor" />
                                    </svg>
                                </a>
                                <div className="card-body">
                                </div>
                            </div>
                        </div>
                        <div className="swiper-slide">
                            <div className="card flq-card-image flq-card-image-instagram">
                                <a href="#" className="card-image">
                                    <span className="flq-image flq-responsive
  flq-responsive-1x1">
                                        <img src="assets/images/background-ticker/8.jpg" alt="" />
                                    </span>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.22506 0.450134C3.03566 0.450134 0.450134 3.03566 0.450134 6.22506V17.7749C0.450134 20.9643 3.03566 23.5498 6.22506 23.5498H17.7749C20.9643 23.5498 23.5498 20.9643 23.5498 17.7749V6.22506C23.5498 3.03566 20.9643 0.450134 17.7749 0.450134H6.22506ZM5.5834 12C5.5834 15.5437 8.45621 18.4166 12 18.4166C15.5437 18.4166 18.4166 15.5437 18.4166 12C18.4166 8.45621 15.5437 5.5834 12 5.5834C8.45621 5.5834 5.5834 8.45621 5.5834 12ZM12 15.8499C14.1263 15.8499 15.8499 14.1263 15.8499 12C15.8499 9.87365 14.1263 8.15003 12 8.15003C9.87366 8.15003 8.15004 9.87365 8.15004 12C8.15004 14.1263 9.87366 15.8499 12 15.8499ZM19.6999 4.30008C19.6999 5.00883 19.1253 5.5834 18.4166 5.5834C17.7078 5.5834 17.1333 5.00883 17.1333 4.30008C17.1333 3.59133 17.7078 3.01677 18.4166 3.01677C19.1253 3.01677 19.6999 3.59133 19.6999 4.30008Z" fill="currentColor" />
                                    </svg>
                                </a>
                                <div className="card-body">
                                </div>
                            </div>
                        </div>
                        <div className="swiper-slide">
                            <div className="card flq-card-image flq-card-image-instagram">
                                <a href="#" className="card-image">
                                    <span className="flq-image flq-responsive
  flq-responsive-1x1">
                                        <img src="assets/images/background-ticker/9.jpg" alt="" />
                                    </span>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.22506 0.450134C3.03566 0.450134 0.450134 3.03566 0.450134 6.22506V17.7749C0.450134 20.9643 3.03566 23.5498 6.22506 23.5498H17.7749C20.9643 23.5498 23.5498 20.9643 23.5498 17.7749V6.22506C23.5498 3.03566 20.9643 0.450134 17.7749 0.450134H6.22506ZM5.5834 12C5.5834 15.5437 8.45621 18.4166 12 18.4166C15.5437 18.4166 18.4166 15.5437 18.4166 12C18.4166 8.45621 15.5437 5.5834 12 5.5834C8.45621 5.5834 5.5834 8.45621 5.5834 12ZM12 15.8499C14.1263 15.8499 15.8499 14.1263 15.8499 12C15.8499 9.87365 14.1263 8.15003 12 8.15003C9.87366 8.15003 8.15004 9.87365 8.15004 12C8.15004 14.1263 9.87366 15.8499 12 15.8499ZM19.6999 4.30008C19.6999 5.00883 19.1253 5.5834 18.4166 5.5834C17.7078 5.5834 17.1333 5.00883 17.1333 4.30008C17.1333 3.59133 17.7078 3.01677 18.4166 3.01677C19.1253 3.01677 19.6999 3.59133 19.6999 4.30008Z" fill="currentColor" />
                                    </svg>
                                </a>
                                <div className="card-body">
                                </div>
                            </div>
                        </div>
                        <div className="swiper-slide">
                            <div className="card flq-card-image flq-card-image-instagram">
                                <a href="#" className="card-image">
                                    <span className="flq-image flq-responsive
  flq-responsive-1x1">
                                        <img src="assets/images/background-ticker/10.jpg" alt="" />
                                    </span>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.22506 0.450134C3.03566 0.450134 0.450134 3.03566 0.450134 6.22506V17.7749C0.450134 20.9643 3.03566 23.5498 6.22506 23.5498H17.7749C20.9643 23.5498 23.5498 20.9643 23.5498 17.7749V6.22506C23.5498 3.03566 20.9643 0.450134 17.7749 0.450134H6.22506ZM5.5834 12C5.5834 15.5437 8.45621 18.4166 12 18.4166C15.5437 18.4166 18.4166 15.5437 18.4166 12C18.4166 8.45621 15.5437 5.5834 12 5.5834C8.45621 5.5834 5.5834 8.45621 5.5834 12ZM12 15.8499C14.1263 15.8499 15.8499 14.1263 15.8499 12C15.8499 9.87365 14.1263 8.15003 12 8.15003C9.87366 8.15003 8.15004 9.87365 8.15004 12C8.15004 14.1263 9.87366 15.8499 12 15.8499ZM19.6999 4.30008C19.6999 5.00883 19.1253 5.5834 18.4166 5.5834C17.7078 5.5834 17.1333 5.00883 17.1333 4.30008C17.1333 3.59133 17.7078 3.01677 18.4166 3.01677C19.1253 3.01677 19.6999 3.59133 19.6999 4.30008Z" fill="currentColor" />
                                    </svg>
                                </a>
                                <div className="card-body">
                                </div>
                            </div>
                        </div>
                        <div className="swiper-slide">
                            <div className="card flq-card-image flq-card-image-instagram">
                                <a href="#" className="card-image">
                                    <span className="flq-image flq-responsive
  flq-responsive-1x1">
                                        <img src="assets/images/background-ticker/11.jpg" alt="" />
                                    </span>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.22506 0.450134C3.03566 0.450134 0.450134 3.03566 0.450134 6.22506V17.7749C0.450134 20.9643 3.03566 23.5498 6.22506 23.5498H17.7749C20.9643 23.5498 23.5498 20.9643 23.5498 17.7749V6.22506C23.5498 3.03566 20.9643 0.450134 17.7749 0.450134H6.22506ZM5.5834 12C5.5834 15.5437 8.45621 18.4166 12 18.4166C15.5437 18.4166 18.4166 15.5437 18.4166 12C18.4166 8.45621 15.5437 5.5834 12 5.5834C8.45621 5.5834 5.5834 8.45621 5.5834 12ZM12 15.8499C14.1263 15.8499 15.8499 14.1263 15.8499 12C15.8499 9.87365 14.1263 8.15003 12 8.15003C9.87366 8.15003 8.15004 9.87365 8.15004 12C8.15004 14.1263 9.87366 15.8499 12 15.8499ZM19.6999 4.30008C19.6999 5.00883 19.1253 5.5834 18.4166 5.5834C17.7078 5.5834 17.1333 5.00883 17.1333 4.30008C17.1333 3.59133 17.7078 3.01677 18.4166 3.01677C19.1253 3.01677 19.6999 3.59133 19.6999 4.30008Z" fill="currentColor" />
                                    </svg>
                                </a>
                                <div className="card-body">
                                </div>
                            </div>
                        </div>
                        <div className="swiper-slide">
                            <div className="card flq-card-image flq-card-image-instagram">
                                <a href="#" className="card-image">
                                    <span className="flq-image flq-responsive
  flq-responsive-1x1">
                                        <img src="assets/images/background-ticker/12.jpg" alt="" />
                                    </span>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.22506 0.450134C3.03566 0.450134 0.450134 3.03566 0.450134 6.22506V17.7749C0.450134 20.9643 3.03566 23.5498 6.22506 23.5498H17.7749C20.9643 23.5498 23.5498 20.9643 23.5498 17.7749V6.22506C23.5498 3.03566 20.9643 0.450134 17.7749 0.450134H6.22506ZM5.5834 12C5.5834 15.5437 8.45621 18.4166 12 18.4166C15.5437 18.4166 18.4166 15.5437 18.4166 12C18.4166 8.45621 15.5437 5.5834 12 5.5834C8.45621 5.5834 5.5834 8.45621 5.5834 12ZM12 15.8499C14.1263 15.8499 15.8499 14.1263 15.8499 12C15.8499 9.87365 14.1263 8.15003 12 8.15003C9.87366 8.15003 8.15004 9.87365 8.15004 12C8.15004 14.1263 9.87366 15.8499 12 15.8499ZM19.6999 4.30008C19.6999 5.00883 19.1253 5.5834 18.4166 5.5834C17.7078 5.5834 17.1333 5.00883 17.1333 4.30008C17.1333 3.59133 17.7078 3.01677 18.4166 3.01677C19.1253 3.01677 19.6999 3.59133 19.6999 4.30008Z" fill="currentColor" />
                                    </svg>
                                </a>
                                <div className="card-body">
                                </div>
                            </div>
                        </div>
                        <div className="swiper-slide">
                            <div className="card flq-card-image flq-card-image-instagram">
                                <a href="#" className="card-image">
                                    <span className="flq-image flq-responsive
  flq-responsive-1x1">
                                        <img src="assets/images/background-ticker/13.jpg" alt="" />
                                    </span>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.22506 0.450134C3.03566 0.450134 0.450134 3.03566 0.450134 6.22506V17.7749C0.450134 20.9643 3.03566 23.5498 6.22506 23.5498H17.7749C20.9643 23.5498 23.5498 20.9643 23.5498 17.7749V6.22506C23.5498 3.03566 20.9643 0.450134 17.7749 0.450134H6.22506ZM5.5834 12C5.5834 15.5437 8.45621 18.4166 12 18.4166C15.5437 18.4166 18.4166 15.5437 18.4166 12C18.4166 8.45621 15.5437 5.5834 12 5.5834C8.45621 5.5834 5.5834 8.45621 5.5834 12ZM12 15.8499C14.1263 15.8499 15.8499 14.1263 15.8499 12C15.8499 9.87365 14.1263 8.15003 12 8.15003C9.87366 8.15003 8.15004 9.87365 8.15004 12C8.15004 14.1263 9.87366 15.8499 12 15.8499ZM19.6999 4.30008C19.6999 5.00883 19.1253 5.5834 18.4166 5.5834C17.7078 5.5834 17.1333 5.00883 17.1333 4.30008C17.1333 3.59133 17.7078 3.01677 18.4166 3.01677C19.1253 3.01677 19.6999 3.59133 19.6999 4.30008Z" fill="currentColor" />
                                    </svg>
                                </a>
                                <div className="card-body">
                                </div>
                            </div>
                        </div>
                        <div className="swiper-slide">
                            <div className="card flq-card-image flq-card-image-instagram">
                                <a href="#" className="card-image">
                                    <span className="flq-image flq-responsive
  flq-responsive-1x1">
                                        <img src="assets/images/background-ticker/14.jpg" alt="" />
                                    </span>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.22506 0.450134C3.03566 0.450134 0.450134 3.03566 0.450134 6.22506V17.7749C0.450134 20.9643 3.03566 23.5498 6.22506 23.5498H17.7749C20.9643 23.5498 23.5498 20.9643 23.5498 17.7749V6.22506C23.5498 3.03566 20.9643 0.450134 17.7749 0.450134H6.22506ZM5.5834 12C5.5834 15.5437 8.45621 18.4166 12 18.4166C15.5437 18.4166 18.4166 15.5437 18.4166 12C18.4166 8.45621 15.5437 5.5834 12 5.5834C8.45621 5.5834 5.5834 8.45621 5.5834 12ZM12 15.8499C14.1263 15.8499 15.8499 14.1263 15.8499 12C15.8499 9.87365 14.1263 8.15003 12 8.15003C9.87366 8.15003 8.15004 9.87365 8.15004 12C8.15004 14.1263 9.87366 15.8499 12 15.8499ZM19.6999 4.30008C19.6999 5.00883 19.1253 5.5834 18.4166 5.5834C17.7078 5.5834 17.1333 5.00883 17.1333 4.30008C17.1333 3.59133 17.7078 3.01677 18.4166 3.01677C19.1253 3.01677 19.6999 3.59133 19.6999 4.30008Z" fill="currentColor" />
                                    </svg>
                                </a>
                                <div className="card-body">
                                </div>
                            </div>
                        </div>
                        <div className="swiper-slide">
                            <div className="card flq-card-image flq-card-image-instagram">
                                <a href="#" className="card-image">
                                    <span className="flq-image flq-responsive flq-responsive-1x1">
                                        <img src="assets/images/background-ticker/15.jpg" alt="" />
                                    </span>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.22506 0.450134C3.03566 0.450134 0.450134 3.03566 0.450134 6.22506V17.7749C0.450134 20.9643 3.03566 23.5498 6.22506 23.5498H17.7749C20.9643 23.5498 23.5498 20.9643 23.5498 17.7749V6.22506C23.5498 3.03566 20.9643 0.450134 17.7749 0.450134H6.22506ZM5.5834 12C5.5834 15.5437 8.45621 18.4166 12 18.4166C15.5437 18.4166 18.4166 15.5437 18.4166 12C18.4166 8.45621 15.5437 5.5834 12 5.5834C8.45621 5.5834 5.5834 8.45621 5.5834 12ZM12 15.8499C14.1263 15.8499 15.8499 14.1263 15.8499 12C15.8499 9.87365 14.1263 8.15003 12 8.15003C9.87366 8.15003 8.15004 9.87365 8.15004 12C8.15004 14.1263 9.87366 15.8499 12 15.8499ZM19.6999 4.30008C19.6999 5.00883 19.1253 5.5834 18.4166 5.5834C17.7078 5.5834 17.1333 5.00883 17.1333 4.30008C17.1333 3.59133 17.7078 3.01677 18.4166 3.01677C19.1253 3.01677 19.6999 3.59133 19.6999 4.30008Z" fill="currentColor" />
                                    </svg>
                                </a>
                                <div className="card-body">
                                </div>
                            </div>
                        </div>
                        <div className="swiper-slide">
                            <div className="card flq-card-image flq-card-image-instagram">
                                <a href="#" className="card-image">
                                    <span className="flq-image flq-responsive
  flq-responsive-1x1">
                                        <img src="assets/images/background-ticker/16.jpg" alt="" />
                                    </span>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.22506 0.450134C3.03566 0.450134 0.450134 3.03566 0.450134 6.22506V17.7749C0.450134 20.9643 3.03566 23.5498 6.22506 23.5498H17.7749C20.9643 23.5498 23.5498 20.9643 23.5498 17.7749V6.22506C23.5498 3.03566 20.9643 0.450134 17.7749 0.450134H6.22506ZM5.5834 12C5.5834 15.5437 8.45621 18.4166 12 18.4166C15.5437 18.4166 18.4166 15.5437 18.4166 12C18.4166 8.45621 15.5437 5.5834 12 5.5834C8.45621 5.5834 5.5834 8.45621 5.5834 12ZM12 15.8499C14.1263 15.8499 15.8499 14.1263 15.8499 12C15.8499 9.87365 14.1263 8.15003 12 8.15003C9.87366 8.15003 8.15004 9.87365 8.15004 12C8.15004 14.1263 9.87366 15.8499 12 15.8499ZM19.6999 4.30008C19.6999 5.00883 19.1253 5.5834 18.4166 5.5834C17.7078 5.5834 17.1333 5.00883 17.1333 4.30008C17.1333 3.59133 17.7078 3.01677 18.4166 3.01677C19.1253 3.01677 19.6999 3.59133 19.6999 4.30008Z" fill="currentColor" />
                                    </svg>
                                </a>
                                <div className="card-body">
                                </div>
                            </div>
                        </div>
                        <div className="swiper-slide">
                            <div className="card flq-card-image flq-card-image-instagram">
                                <a href="#" className="card-image">
                                    <span className="flq-image flq-responsive
  flq-responsive-1x1">
                                        <img src="assets/images/background-ticker/17.jpg" alt="" />
                                    </span>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.22506 0.450134C3.03566 0.450134 0.450134 3.03566 0.450134 6.22506V17.7749C0.450134 20.9643 3.03566 23.5498 6.22506 23.5498H17.7749C20.9643 23.5498 23.5498 20.9643 23.5498 17.7749V6.22506C23.5498 3.03566 20.9643 0.450134 17.7749 0.450134H6.22506ZM5.5834 12C5.5834 15.5437 8.45621 18.4166 12 18.4166C15.5437 18.4166 18.4166 15.5437 18.4166 12C18.4166 8.45621 15.5437 5.5834 12 5.5834C8.45621 5.5834 5.5834 8.45621 5.5834 12ZM12 15.8499C14.1263 15.8499 15.8499 14.1263 15.8499 12C15.8499 9.87365 14.1263 8.15003 12 8.15003C9.87366 8.15003 8.15004 9.87365 8.15004 12C8.15004 14.1263 9.87366 15.8499 12 15.8499ZM19.6999 4.30008C19.6999 5.00883 19.1253 5.5834 18.4166 5.5834C17.7078 5.5834 17.1333 5.00883 17.1333 4.30008C17.1333 3.59133 17.7078 3.01677 18.4166 3.01677C19.1253 3.01677 19.6999 3.59133 19.6999 4.30008Z" fill="currentColor" />
                                    </svg>
                                </a>
                                <div className="card-body">
                                </div>
                            </div>
                        </div>
                        <div className="swiper-slide">
                            <div className="card flq-card-image flq-card-image-instagram">
                                <a href="#" className="card-image">
                                    <span className="flq-image flq-responsive
  flq-responsive-1x1">
                                        <img src="assets/images/background-ticker/18.jpg" alt="" />
                                    </span>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.22506 0.450134C3.03566 0.450134 0.450134 3.03566 0.450134 6.22506V17.7749C0.450134 20.9643 3.03566 23.5498 6.22506 23.5498H17.7749C20.9643 23.5498 23.5498 20.9643 23.5498 17.7749V6.22506C23.5498 3.03566 20.9643 0.450134 17.7749 0.450134H6.22506ZM5.5834 12C5.5834 15.5437 8.45621 18.4166 12 18.4166C15.5437 18.4166 18.4166 15.5437 18.4166 12C18.4166 8.45621 15.5437 5.5834 12 5.5834C8.45621 5.5834 5.5834 8.45621 5.5834 12ZM12 15.8499C14.1263 15.8499 15.8499 14.1263 15.8499 12C15.8499 9.87365 14.1263 8.15003 12 8.15003C9.87366 8.15003 8.15004 9.87365 8.15004 12C8.15004 14.1263 9.87366 15.8499 12 15.8499ZM19.6999 4.30008C19.6999 5.00883 19.1253 5.5834 18.4166 5.5834C17.7078 5.5834 17.1333 5.00883 17.1333 4.30008C17.1333 3.59133 17.7078 3.01677 18.4166 3.01677C19.1253 3.01677 19.6999 3.59133 19.6999 4.30008Z" fill="currentColor" />
                                    </svg>
                                </a>
                                <div className="card-body">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default connect(null, {})(MediaCloudCarousel);