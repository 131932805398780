import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const OffCanvas = ({}) => {
    return (
        <div className="offcanvas offcanvas-start" tabindex="-1" id="flq_offcanvas">
            <div className="offcanvas-header">
                <Link to="/" className="flq-fancybox-brand me-auto">
                    <img src={require("../../assets/images/logo.png")} className="flq-logo" alt="" />
                </Link>
                <button type="button" className="btn btn-link offcanvas-close" data-bs-dismiss="offcanvas" aria-label="Close">
                    <span></span>
                </button>
            </div>
            <div className="offcanvas-body">
                <div className="flq-widget flq-widget-search">
                    <h4 className="flq-widget-title h5"><span>Search</span></h4>
                    <form action="#">
                        <input className="form-control" type="search" placeholder="Search" />
                        <button className="btn btn-link btn-icon-sm">
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.0833 17.4167C14.1334 17.4167 17.4167 14.1334 17.4167 10.0833C17.4167 6.03325 14.1334 2.75 10.0833 2.75C6.03325 2.75 2.75 6.03325 2.75 10.0833C2.75 14.1334 6.03325 17.4167 10.0833 17.4167Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M19.25 19.25L15.2625 15.2625" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </button>
                    </form>
                </div>
                <div className="flq-widget flq-widget-blog">
                    <h4 className="flq-widget-title h5"><span>Recent Posts</span></h4>
                    <ul>
                        <li>
                            <div className="card flq-card-blog">
                                <div className="card-img-wrap">
                                    <a href="single-post.html">
                                        <span className="flq-image flq-responsive">
                                            <img src="assets/images/blog/post-1-740x420.jpg" alt="" />
                                        </span>
                                        <span className="card-badge badge badge-dark badge-glass">November 20, 2021</span>
                                    </a>
                                </div>
                                <div className="card-body">
                                    <h5 className="card-title h5"><a href="single-post.html">Girlfriend. How and why</a></h5>
                                    <p>Earth first saw earth which seas void i thing said bearing behold and can&#39;t unto. Blessed...</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="card flq-card-blog">
                                <div className="card-img-wrap">
                                    <a href="single-post.html">
                                        <span className="flq-image flq-responsive">
                                            <img src="assets/images/blog/post-2-740x420.jpg" alt="" />
                                        </span>
                                        <span className="card-badge badge badge-dark badge-glass">November 18, 2021</span>
                                    </a>
                                </div>
                                <div className="card-body">
                                    <h5 className="card-title h5"><a href="single-post.html">Years is air whose lesser. You&#39;ll thing waters unto brought.</a></h5>
                                    <p>From said, our they&#39;re darkness void meat from, bearing. Void, beginning fruitful he were...</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="card flq-card-blog">
                                <div className="card-img-wrap">
                                    <a href="single-post.html">
                                        <span className="flq-image flq-responsive">
                                            <img src="assets/images/blog/post-3-740x420.jpg" alt="" />
                                        </span>
                                        <span className="card-badge badge badge-dark badge-glass">November 15, 2021</span>
                                    </a>
                                </div>
                                <div className="card-body">
                                    <h5 className="card-title h5"><a href="single-post.html">Is under void created his hath. One moving Appear hath.</a></h5>
                                    <p>Spirit herb subdue second form lights green isn&#39;t a sixth had creepeth is night spirit...</p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="flq-widget flq-widget-tag">
                    <h4 className="flq-widget-title h5"><span>Tags</span></h4>
                    <div className="flq-tags">
                        <ul>
                            <li>
                                <a href="#" className="flq-tag">#Comedy</a>
                            </li>
                            <li>
                                <a href="#" className="flq-tag">#Drama</a>
                            </li>
                            <li>
                                <a href="#" className="flq-tag">#Trailers</a>
                            </li>
                            <li>
                                <a href="#" className="flq-tag">#Movie Trailers</a>
                            </li>
                            <li>
                                <a href="#" className="flq-tag">#Horror</a>
                            </li>
                            <li>
                                <a href="#" className="flq-tag">#Thriller</a>
                            </li>
                            <li>
                                <a href="#" className="flq-tag">#TV Shows</a>
                            </li>
                            <li>
                                <a href="#" className="flq-tag">#Action</a>
                            </li>
                            <li>
                                <a href="#" className="flq-tag">#Music</a>
                            </li>
                            <li>
                                <a href="#" className="flq-tag">#Rider</a>
                            </li>
                            <li>
                                <a href="#" className="flq-tag">#Travel</a>
                            </li>
                            <li>
                                <a href="#" className="flq-tag">#Car</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default connect(null, {})(OffCanvas);