import React from "react";
import {connect} from 'react-redux';
import { Swiper, SwiperSlide } from "swiper/react";
import { update_page_meta } from "../actions/page/header";
import AppDownload from "../components/AppDownload";
import BestSerials from "../components/home/BestSerials";
import Documentary from "../components/home/Documentary";
import Features from "../components/home/Features";
import NewReleases from "../components/home/NewReleases";
import OnlineShows from "../components/home/OnlineShows";
import PartnershipSlider from "../components/home/PartnershipSlider";
import SwiperMain from "../components/home/SwiperMain";
import UpcomingMovies from "../components/home/UpcomingMovies";
import MediaCloudCarousel from "../components/MediaCloudCarousel";



const Home = ({ update_page_meta }) => {
    update_page_meta({
        title: "Home | Gurus TV",
        description: "Narayana Guru (ca. 1854–1928), also seen as Sree Narayana Guru, was a Hindu sant, sadhu and social reformer of India.",
        ogimgurl: require("../assets/images/logo.png"),
        ogimgwidth: "575",
        ogimgheight: "575"
    })
    return (
        <>
            <SwiperMain />
            <NewReleases />
            {/* <UpcomingMovies /> */}
            <Documentary />
            {/* <BestSerials /> */}
            {/* <Features /> */}
            {/* <OnlineShows /> */}
            {/* <PartnershipSlider /> */}
            {/* <AppDownload /> */}
            {/* <MediaCloudCarousel /> */}
        </>
    )
}

export default connect(null, { update_page_meta })(Home);