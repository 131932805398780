export const Albums = [
    {
        name: "ഗുരു സാഗര സന്ധ്യ",
        slug: "guru-sagara-sandhya",
        description: "Presenting to you this album, Guru Sagara Sandhya, to spread Sree Narayana Guru's principles, composed by Vidyadharan Master and produced by B. Ram Musics ",
        thumbnails: [
            {
                size: "3840x2160",
                alt: "",
                img_src: ""
            },
            {
                size: "1290x1290",
                alt: "Guru Sagara Sandhya",
                img_src: require("../assets/images/albums/guru_sagara_sandhya_1290x1290.webp")
            },
        ],
        artists: [
            {
                name: "Vidyadharan Master",
            },
            {
                name: "M G Sreekumar",
            },
            {
                name: "Madhu Balakrishnan",
            },
            {
                name: "P Jayachandran",
            },
            {
                name: "Vaikom Vijaya Lakshmi",
            },
            {
                name: "Rafeek Ahamed",
            },
            {
                name: "K Jayakumar",
            },
            {
                name: "Raj Mohan",
            }
        ],
        song_count: 6,
        year: 2023,
        songs: [
            {
                title: "ദൈവമേ കാത്തു കൊൾകങ്ങ്",
                slug: "daivame-kathukolkangu",
                description_text: "Daivadasakam, penned by Sree Narayana Guru in 1914, is a secular prayer that can be used by people irrespective of their caste, creed and religion. It has been translated to more than 100 languages. Daivadasakam philosophically follow the Adwaitha theory. Beginning by depicting the dual concept of relationship between God and the devotee, step by step it progress to the concept of Adwaitha theory that tell everything is one.",
                description:<>
                                <p>Daivadasakam, penned by Sree Narayana Guru in 1914, is a secular prayer that can be used by people 
                                    irrespective of their caste, creed and religion. It has been translated to more than 100 languages. 
                                    Daivadasakam philosophically follow the Adwaitha theory. 
                                    Beginning by depicting the dual concept of relationship between God and the devotee, step by step it 
                                    progress to the concept of Adwaitha theory that tell everything is one.</p>
                            </>,
                duration: "5:14",
                youtube_link: "https://www.youtube.com/watch?v=tzVi9-FqO_s",
                year: 2023,
                artists: [
                    {
                        name: "P Jayachandran",
                    },
                    {
                        name: "Vidyadharan Master",
                    }
                ],
                thumbnails: [
                    {
                        size: "3840x2160",
                        alt: "",
                        img_src: require("../assets/images/songs/daivadasakam_3840x2160.webp")
                    },
                    {
                        size: "1290x1290",
                        alt: "",
                        img_src: require("../assets/images/songs/daivadasakam_1290x1290.webp")
                    },
                    {
                        size: "390x440",
                        alt: "",
                        img_src: require("../assets/images/songs/daivadasakam_390x440.webp")
                    },
                ]
            },
            {
                title: "മത മഹാ പാഠശാല",
                slug: "matha-maha-padasala",
                description_text: "'WE MEET HERE NOT TO ARGUE AND WIN, BUT TO KNOW AND TO BE KNOWN.’ - These were the words displayed at the entrance of the Asia’s first and World’s second Sarva Matha Sammelanam (All Religions Meet) held at Aluva Adwaithashram, a shrine quite close to the birth place of Adi Shankaracharya,  led by Sree Narayana Guru on 10th February, 1924 to highlight the ideology of ‘oneness’. Gurudevan issued a directive about Brahma Vidyalaya at the end of the two day conference.",
                description:<>
                                <blockquote>'WE MEET HERE NOT TO ARGUE AND WIN, BUT TO KNOW AND TO BE KNOWN.’</blockquote>
                                <p>These were the words displayed at the entrance of the Asia’s first and World’s second Sarva Matha 
                                    Sammelanam (All Religions Meet) held at Aluva Adwaithashram, a shrine quite close to the birth place 
                                    of Adi Shankaracharya,  led by Sree Narayana Guru on 10th February, 1924 to highlight the ideology of 
                                    ‘oneness’. Gurudevan issued a directive about Brahma Vidyalaya at the end of the two day conference.</p>
                            </>,
                duration: "4:32",
                youtube_link: "https://www.youtube.com/watch?v=U-UqIQpTLwM",
                year: 2023,
                artists: [
                    {
                        name: "Sreekumaran Thampi",
                    },
                    {
                        name: "Madhu Balakrishnan",
                    },
                    {
                        name: "Vidyadharan Master",
                    }
                ],
                thumbnails: [
                    {
                        size: "3840x2160",
                        alt: "",
                        img_src: require("../assets/images/songs/sarvamathapadasala_3840x2160.webp")
                    },
                    {
                        size: "1290x1290",
                        alt: "",
                        img_src: require("../assets/images/songs/sarvamathapadasala_1290x1290.webp")
                    },
                    {
                        size: "390x440",
                        alt: "",
                        img_src: require("../assets/images/songs/sarvamathapadasala_390x440.webp")
                    },
                ]
            },
            {
                title: "നാരായണ മൂർത്തേ",
                slug: "narayana-murthe",
                description_text: "Gurustavam, also known as Samajastuti, is a hymn written by the great poet Kumaranashan on the occasion of Sree Narayana Guru’s 60th birthday. Over the past century, hundreds of thousands of Sri Narayanis have used this hymn for worshipping and expressing their gratitude towards Sree Narayana Guru.",
                description:<>
                                <p>
                                    Gurustavam, also known as Samajastuti, is a hymn written by the great poet Kumaranashan on the 
                                    occasion of Sree Narayana Guru’s 60th birthday. Over the past century, hundreds of thousands of Sri 
                                    Narayanis have used this hymn for worshipping and expressing their gratitude towards Sree Narayana Guru.
                                </p>
                            </>,
                duration: "4:05",
                youtube_link: "https://www.youtube.com/watch?v=TMZn5j_l2eM",
                year: 2023,
                artists: [
                    {
                        name: "Mahakavi Kumaranasan",
                    },
                    {
                        name: "Raj Mohan",
                    },
                    {
                        name: "Vidyadharan Master",
                    }
                ],
                thumbnails: [
                    {
                        size: "3840x2160",
                        alt: "",
                        img_src: require("../assets/images/songs/gurusthavam_3840x2160.webp")
                    },
                    {
                        size: "1290x1290",
                        alt: "",
                        img_src: require("../assets/images/songs/gurusthavam_1290x1290.webp")
                    },
                    {
                        size: "390x440",
                        alt: "",
                        img_src: require("../assets/images/songs/gurusthavam_390x440.webp")
                    },
                ]
            },
            {
                title: "ഒഴുകുകയായ്",
                slug: "ozhukukayayi",
                description_text: "Chandalabhikshuki is a collection of poems written by the Malayalam poet Kumaranasan. Through Chandalabhikshuki, Kumaranasan is trying to reveal the meaninglessness of caste customs.",
                description:<>
                                <p>
                                    Chandalabhikshuki is a collection of poems written by the Malayalam poet Kumaranasan. 
                                    Through Chandalabhikshuki, Kumaranasan is trying to reveal the meaninglessness of caste customs.
                                </p>
                            </>,
                duration: "5:04",
                youtube_link: "https://www.youtube.com/watch?v=hQsmakUPizE",
                year: 2023,
                artists: [
                    {
                        name: "K Jayakumar",
                    },
                    {
                        name: "Vaikom Vijayalakshmi",
                    },
                    {
                        name: "Vidyadharan Master",
                    }
                ],
                thumbnails: [
                    {
                        size: "3840x2160",
                        alt: "",
                        img_src: require("../assets/images/songs/ozhukukayay_3840x2160.webp")
                    },
                    {
                        size: "1290x1290",
                        alt: "",
                        img_src: require("../assets/images/songs/ozhukukayay_1290x1290.webp")
                    },
                    {
                        size: "390x440",
                        alt: "",
                        img_src: require("../assets/images/songs/ozhukukayay_390x440.webp")
                    },
                ]
            },
            {
                title: "സാഗര സംഗമം",
                slug: "sagara-sangamam",
                description_text: "'I have been touring different parts of the world. But I have never come across one who is spiritually greater than Sree Narayana Guru' - The great Nobel Laureate Rabindranath Tagore met Sree Narayana Guru in 1922. Above are his words about his warm meeting with Sree Narayana Guru.",
                description:<>
                                <blockquote>
                                    "I have been touring different parts of the world. But I have never come across one who is 
                                    spiritually greater than Sree Narayana Guru"
                                </blockquote>

                                <p>The great Nobel Laureate Rabindranath Tagore met Sree Narayana Guru in 1922. Above are his 
                                    words about his warm meeting with Sree Narayana Guru.
                                </p>
                            </>,
                duration: "5:06",
                youtube_link: "https://www.youtube.com/watch?v=NRecPqFp5Pk",
                year: 2023,
                artists: [
                    {
                        name: "Rafeek Ahamed",
                    },
                    {
                        name: "M G Sreekumar",
                    },
                    {
                        name: "Vidyadharan Master",
                    }
                ],
                thumbnails: [
                    {
                        size: "3840x2160",
                        alt: "",
                        img_src: require("../assets/images/songs/sagarasangamam_3840x2160.webp")
                    },
                    {
                        size: "1290x1290",
                        alt: "",
                        img_src: require("../assets/images/songs/sagarasangamam_1290x1290.webp")
                    },
                    {
                        size: "390x440",
                        alt: "",
                        img_src: require("../assets/images/songs/sagarasangamam_390x440.webp")
                    },
                ]
            },
            {
                title: "ശിവഗിരി തേടും",
                slug: "sivagiri-thedum",
                description_text: "Sivagiri hills is a pilgrimage centre of Varkala town, where the tomb or Samadhi of Sree Narayana Guru is situated. It is the place where Sree Narayana Guru built the Sharada temple and dedicated to Sharada Devi. ",
                description:<>
                                <p>
                                    Sivagiri hills is a pilgrimage centre of Varkala town, where the tomb or Samadhi of Sree Narayana
                                    Guru is situated. It is the place where Sree Narayana Guru built the Sharada temple and dedicated to
                                    Sharada Devi. 
                                </p>
                            </>,
                duration: "4:18",
                youtube_link: "https://www.youtube.com/watch?v=fUkvChNu5Ek",
                year: 2023,
                artists: [
                    {
                        name: "Rafeek Ahamed",
                    },
                    {
                        name: "Madhu Balakrishnan",
                    },
                    {
                        name: "Vidyadharan Master",
                    }
                ],
                thumbnails: [
                    {
                        size: "3840x2160",
                        alt: "",
                        img_src: require("../assets/images/songs/sivagirithedum_3840x2160.webp")
                    },
                    {
                        size: "1290x1290",
                        alt: "",
                        img_src: require("../assets/images/songs/sivagirithedum_1290x1290.webp")
                    },
                    {
                        size: "390x440",
                        alt: "",
                        img_src: require("../assets/images/songs/sivagirithedum_390x440.webp")
                    },
                ]
            },
        ]
    }
]