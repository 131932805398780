import React from "react";
import { connect } from "react-redux";

const BlogDetail = ({}) => {
    return (
        <>
            <div className="flq-background pt-7 pb-6">
                <div className="flq-background-image">
                    <span className="flq-image jarallax" data-speed={0.7}>
                        <img src={require("../assets/images/blog/post-1-3840x2160.jpg")} className="jarallax-img" alt="" />
                    </span>
                </div>
                <div className="flq-background-overlay" style={{backgroundImage: "linear-gradient(0deg, hsla(var(--flq-color-black), 0.7) 0%, hsla(var(--flq-color-black), 0) 40%)"}}></div>
                <div className="container mt-7 pt-7" data-sr="post-header" data-sr-interval="100" data-sr-duration="1000" data-sr-distance="10">
                    <div className="flq-subtitle badge badge-dark badge-glass badge-translucent flq-color-white" data-sr-item="post-header">Analysis</div>
                    <h1 className="display-5 mb-3 elem-visible" data-sr-item="post-header">Girlfriend. How and why</h1>
                    <div className="flq-meta flq-color-opacity">
                        <ul className="gy-1 gx-4">
                            <li className="elem-visible" data-sr-item="post-header">
                                <a href="#">David H.</a>
                            </li>
                            <li className="elem-visible" data-sr-item="post-header">
                                <a href="#">November 20, 2021</a>
                            </li>
                            {/* <li className="elem-visible" data-sr-item="post-header">
                                <a href="#">2 Comments</a>
                            </li> */}
                        </ul>
                    </div>
                </div>
            </div>

            <div class="container py-7">
                <div class="row gy-6 gx-6">
                    <div class="col-12 col-xl">
                        <div class="flq-post-content flq-vertical-rhythm">
                            <p>As collected deficient objection by it discovery sincerity curiosity. Quiet decay who round three world whole has mrs man. Built the china there tried jokes which gay why. Assure in adieus wicket it is. But spoke <a href="#">round point</a> and one joy. Offending her moonlight men sweetness see unwilling. Often of it tears whole oh balls share an.</p>
                            <p>Way ham unwilling not <strong>breakfast furniture explained</strong> perpetual. Or mr surrounded conviction so astonished literature. Songs to an blush woman be sorry young. We certain as removal attempt.</p>
                            <blockquote>Cottage out enabled was entered greatly prevent message. No procured unlocked an likewise. Dear but what she been over gay felt body. Six principles advantages and use entreaties</blockquote>
                            <p>Are own design entire former get should. Advantages boisterous day excellence boy. Out between our two waiting wishing. Pursuit he he garrets greater towards amiable so placing.</p>
                            <p>Frankness applauded by supported ye household. Collected favourite now for for and rapturous repulsive consulted. An seems green be wrote again. She add what own only like. Tolerably we as extremity exquisite do commanded. Doubtful offended do entrance of landlord moreover is mistress in. Nay was appear entire ladies. Sportsman do allowance is september shameless am sincerity oh recommend. Gate tell man day that who.</p>
                            <div class="card flq-card-image">
                                <a href="assets/images/blog/post-1-inner-3840x2160.jpg" class="card-image" data-fancybox>
                                    <span class="flq-image flq-responsive flq-responsive-sm-21x9">
                                        <img src={require("../assets/images/blog/post-1-inner-1840x860.jpg")} alt="" />
                                    </span>
                                    <svg width="38" height="38" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M20 20H16M4 8V4V8ZM4 4H8H4ZM4 4L9 9L4 4ZM20 8V4V8ZM20 4H16H20ZM20 4L15 9L20 4ZM4 16V20V16ZM4 20H8H4ZM4 20L9 15L4 20ZM20 20L15 15L20 20ZM20 20V16V20Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </a>
                                <div class="card-body">
                                </div>
                            </div>
                            <p>Frankness applauded by supported ye household. Collected favourite for for and rapturous repulsive consulted. An seems green be wrote again. She add what only like. Tolerably we as extremity exquisite do commanded.</p>
                            <div class="flq-tags" data-sr="post-tags" data-sr-distance="10" data-sr-interval="80" data-sr-duration="1000">
                                <ul>
                                    <li data-sr-item="post-tags">
                                        <a href="#" class="flq-tag">#Comedy</a>
                                    </li>
                                    <li data-sr-item="post-tags">
                                        <a href="#" class="flq-tag">#Trailers</a>
                                    </li>
                                    <li data-sr-item="post-tags">
                                        <a href="#" class="flq-tag">#Action</a>
                                    </li>
                                </ul>
                            </div>

                            {/* Author */}
                            {/* <div class="flq-post-author" data-sr data-sr-distance="10" data-sr-duration="1000">
                                <div class="flq-post-author-head">
                                    <div class="flq-media">
                                        <div class="flq-media-image">
                                            <span class="flq-image">
                                                <img src={require("../assets/images/user-160x160.jpg")} alt="" />
                                            </span>
                                        </div>
                                        <div class="flq-media-meta">
                                            <h5 class="flq-media-title">David Hill</h5>
                                            <div class="flq-meta">
                                                <ul>
                                                    <li>Posts by Author</li>
                                                    <li>8 posts published</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flq-social flq-social-sm">
                                        <ul>
                                            <li>
                                                <a href="#" class="flq-social-link">
                                                    <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M23.625 12C23.625 5.57812 18.4219 0.375 12 0.375C5.57812 0.375 0.375 5.57812 0.375 12C0.375 17.8022 4.62609 22.6116 10.1836 23.4844V15.3605H7.23047V12H10.1836V9.43875C10.1836 6.52547 11.918 4.91625 14.5744 4.91625C15.8466 4.91625 17.1769 5.14313 17.1769 5.14313V8.0025H15.7106C14.2669 8.0025 13.8164 8.89875 13.8164 9.81797V12H17.0405L16.5248 15.3605H13.8164V23.4844C19.3739 22.6116 23.625 17.8022 23.625 12Z" fill="currentColor" />
                                                    </svg>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#" class="flq-social-link">
                                                    <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M21.533 7.11169C21.5482 7.32488 21.5482 7.53811 21.5482 7.7513C21.5482 14.2538 16.599 21.7462 7.5533 21.7462C4.76648 21.7462 2.17767 20.9391 0 19.5381C0.395953 19.5838 0.776625 19.599 1.18781 19.599C3.48727 19.599 5.60405 18.8224 7.29441 17.4975C5.13197 17.4518 3.31978 16.0356 2.69541 14.0863C3 14.132 3.30455 14.1624 3.62437 14.1624C4.06598 14.1624 4.50764 14.1015 4.91878 13.995C2.66498 13.5381 0.974578 11.5584 0.974578 9.16753V9.10664C1.62937 9.47213 2.39086 9.70055 3.19791 9.73097C1.87303 8.8477 1.00505 7.34011 1.00505 5.63452C1.00505 4.72083 1.24866 3.88327 1.67508 3.1523C4.09641 6.13706 7.73601 8.08627 11.8172 8.2995C11.7411 7.93402 11.6954 7.55335 11.6954 7.17263C11.6954 4.46194 13.8883 2.25385 16.6141 2.25385C18.0304 2.25385 19.3095 2.84775 20.208 3.80714C21.3197 3.59395 22.3857 3.18277 23.3299 2.61933C22.9643 3.76149 22.1877 4.72088 21.1674 5.32997C22.1573 5.22342 23.1167 4.94925 23.9999 4.56858C23.33 5.54316 22.4924 6.41114 21.533 7.11169V7.11169Z" fill="currentColor" />
                                                    </svg>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="flq-post-author-body">
                                    <p>Doubtful two bed way pleasure confined followed. Shew up ye away no eyes life or were this. Perfectly did suspicion daughters but his intention. Started on society an brought it explain. Position two saw greatest stronger old. Pianoforte if at simplicity do estimating.</p>
                                </div>
                            </div> */}

                            <div>
                                <div class="row gx-1 gy-1" data-sr="post-social" data-sr-distance="10" data-sr-interval="80" data-sr-duration="1000">
                                    <div class="col" data-sr-item="post-social">
                                        <a href="#" class="btn btn-block btn-sm btn-facebook btn-icon-xs">
                                            <span class="btn-icon">
                                                <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M23.625 12C23.625 5.57812 18.4219 0.375 12 0.375C5.57812 0.375 0.375 5.57812 0.375 12C0.375 17.8022 4.62609 22.6116 10.1836 23.4844V15.3605H7.23047V12H10.1836V9.43875C10.1836 6.52547 11.918 4.91625 14.5744 4.91625C15.8466 4.91625 17.1769 5.14313 17.1769 5.14313V8.0025H15.7106C14.2669 8.0025 13.8164 8.89875 13.8164 9.81797V12H17.0405L16.5248 15.3605H13.8164V23.4844C19.3739 22.6116 23.625 17.8022 23.625 12Z" fill="currentColor" />
                                                </svg>
                                            </span>
                                            <span class="btn-name">Share</span>
                                        </a>
                                    </div>
                                    <div class="col" data-sr-item="post-social">
                                        <a href="#" class="btn btn-block btn-sm btn-twitter btn-icon-xs">
                                            <span class="btn-icon">
                                                <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M21.533 7.11169C21.5482 7.32488 21.5482 7.53811 21.5482 7.7513C21.5482 14.2538 16.599 21.7462 7.5533 21.7462C4.76648 21.7462 2.17767 20.9391 0 19.5381C0.395953 19.5838 0.776625 19.599 1.18781 19.599C3.48727 19.599 5.60405 18.8224 7.29441 17.4975C5.13197 17.4518 3.31978 16.0356 2.69541 14.0863C3 14.132 3.30455 14.1624 3.62437 14.1624C4.06598 14.1624 4.50764 14.1015 4.91878 13.995C2.66498 13.5381 0.974578 11.5584 0.974578 9.16753V9.10664C1.62937 9.47213 2.39086 9.70055 3.19791 9.73097C1.87303 8.8477 1.00505 7.34011 1.00505 5.63452C1.00505 4.72083 1.24866 3.88327 1.67508 3.1523C4.09641 6.13706 7.73601 8.08627 11.8172 8.2995C11.7411 7.93402 11.6954 7.55335 11.6954 7.17263C11.6954 4.46194 13.8883 2.25385 16.6141 2.25385C18.0304 2.25385 19.3095 2.84775 20.208 3.80714C21.3197 3.59395 22.3857 3.18277 23.3299 2.61933C22.9643 3.76149 22.1877 4.72088 21.1674 5.32997C22.1573 5.22342 23.1167 4.94925 23.9999 4.56858C23.33 5.54316 22.4924 6.41114 21.533 7.11169V7.11169Z" fill="currentColor" />
                                                </svg>
                                            </span>
                                            <span class="btn-name">Tweet</span>
                                        </a>
                                    </div>
                                    <div class="col" data-sr-item="post-social">
                                        <a href="#" class="btn btn-block btn-sm btn-pinterest btn-icon-xs">
                                            <span class="btn-icon">
                                                <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M23.625 12C23.625 18.4219 18.4219 23.625 12 23.625C10.8 23.625 9.64688 23.4422 8.55938 23.1047C9.03281 22.3312 9.74062 21.0656 10.0031 20.0578C10.1437 19.5141 10.725 17.2922 10.725 17.2922C11.1047 18.0141 12.2109 18.6281 13.3875 18.6281C16.8938 18.6281 19.4203 15.4031 19.4203 11.3953C19.4203 7.55625 16.2844 4.68281 12.2531 4.68281C7.2375 4.68281 4.57031 8.04844 4.57031 11.7188C4.57031 13.425 5.47969 15.5484 6.92813 16.2234C7.14844 16.3266 7.26563 16.2797 7.31719 16.0687C7.35469 15.9094 7.55156 15.1172 7.64062 14.7516C7.66875 14.6344 7.65469 14.5313 7.56094 14.4188C7.0875 13.8328 6.70312 12.7641 6.70312 11.7656C6.70312 9.20156 8.64375 6.72187 11.9531 6.72187C14.8078 6.72187 16.8094 8.66719 16.8094 11.4516C16.8094 14.5969 15.2203 16.7766 13.1531 16.7766C12.0141 16.7766 11.1562 15.8344 11.4328 14.6766C11.7609 13.2938 12.3937 11.8031 12.3937 10.8047C12.3937 9.91406 11.9156 9.16875 10.9219 9.16875C9.75469 9.16875 8.81719 10.3734 8.81719 11.9906C8.81719 13.0219 9.16406 13.7156 9.16406 13.7156C9.16406 13.7156 8.01562 18.5812 7.80469 19.4906C7.57031 20.4937 7.66406 21.9094 7.7625 22.8281C3.44063 21.1359 0.375 16.9266 0.375 12C0.375 5.57812 5.57812 0.375 12 0.375C18.4219 0.375 23.625 5.57812 23.625 12Z" fill="currentColor" />
                                                </svg>
                                            </span>
                                            <span class="btn-name">Pin</span>
                                        </a>
                                    </div>
                                    <div class="col" data-sr-item="post-social">
                                        <a href="#" class="btn btn-block btn-sm btn-linkedin btn-icon-xs">
                                            <span class="btn-icon">
                                                <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M16 8C17.5913 8 19.1174 8.63214 20.2426 9.75736C21.3679 10.8826 22 12.4087 22 14V21H18V14C18 13.4696 17.7893 12.9609 17.4142 12.5858C17.0391 12.2107 16.5304 12 16 12C15.4696 12 14.9609 12.2107 14.5858 12.5858C14.2107 12.9609 14 13.4696 14 14V21H10V14C10 12.4087 10.6321 10.8826 11.7574 9.75736C12.8826 8.63214 14.4087 8 16 8Z" fill="currentColor" />
                                                    <path d="M6 9H2V21H6V9Z" fill="currentColor" />
                                                    <path d="M4 6C5.10457 6 6 5.10457 6 4C6 2.89543 5.10457 2 4 2C2.89543 2 2 2.89543 2 4C2 5.10457 2.89543 6 4 6Z" fill="currentColor" />
                                                </svg>
                                            </span>
                                            <span class="btn-name">Share</span>
                                        </a>
                                    </div>
                                    <div class="col" data-sr-item="post-social">
                                        <a href="#" class="btn btn-block btn-sm btn-dark btn-icon-xs">
                                            <span class="btn-icon">
                                                <svg width="18" height="18" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.88322 6.94244L15 14.7242L26.1168 6.94244C25.9101 6.53365 25.485 6.25 25 6.25H5C4.51505 6.25 4.08995 6.53365 3.88322 6.94244ZM1.25 7.5C1.25 5.43464 2.93464 3.75 5 3.75H25C27.0654 3.75 28.75 5.43464 28.75 7.5V22.5C28.75 24.5654 27.0654 26.25 25 26.25H5C2.93464 26.25 1.25 24.5654 1.25 22.5V7.5Z" fill="currentColor" />
                                                </svg>
                                            </span>
                                            <span class="btn-name">Share</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <nav class="flq-page-navigation">
                                <span class="flq-page-navigation-container">
                                    <a class="flq-page-navigation-prev flq-page-navigation-has-image" href="single-post.html">
                                        <span class="flq-page-navigation-subtitle">
                                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M18 21L12 15L18 9" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                            <span>Previous Post</span>
                                        </span>
                                        <span class="flq-page-navigation-content">
                                            <span class="flq-page-navigation-image">
                                                <span class="flq-image">
                                                    <img src={require("../assets/images/blog/post-2-740x420.jpg")} alt="" />
                                                </span>
                                            </span>
                                            <span class="flq-page-navigation-title h4">Years is air whose lesser. You&#39;ll thing waters unto brought.</span>
                                            <span class="btn btn-link mt-3">View Post</span>
                                        </span>
                                    </a>
                                    <a class="flq-page-navigation-next flq-page-navigation-has-image" href="single-post.html">
                                        <span class="flq-page-navigation-subtitle">
                                            <span>Next Post</span>
                                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 21L18 15L12 9" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </span>
                                        <span class="flq-page-navigation-content">
                                            <span class="flq-page-navigation-image">
                                                <span class="flq-image">
                                                    <img src={require("../assets/images/blog/post-3-740x420.jpg")} alt="" />
                                                </span>
                                            </span>
                                            <span class="flq-page-navigation-title h4">Is under void created his hath. One moving Appear hath.</span>
                                            <span class="btn btn-link mt-3">View Post</span>
                                        </span>
                                    </a>
                                </span>
                            </nav>

                            {/* Reviews and form */}
                            {/* <h3 class="mt-7 mb-5">2 thoughts on “Extraction”</h3>
                            <div class="flq-comments" data-sr="post-comment" data-sr-distance="10" data-sr-interval="80" data-sr-duration="1000">
                                <ul>
                                    <li class="flq-comment" data-sr-item="post-comment">
                                        <div class="flq-media">
                                            <div class="flq-media-image">
                                                <span class="flq-image">
                                                    <img src={require("../assets/images/user-160x160.jpg")} alt="" />
                                                </span>
                                            </div>
                                            <div class="flq-media-meta">
                                                <h5 class="flq-media-title">David Hill</h5>
                                                <div class="flq-meta">
                                                    <ul>
                                                        <li><a href="#">January 6, 2021 at 1:24 pm</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flq-comment-content flq-vertical-rhythm">
                                            <p>To no indulgence diminution so discovered mr apartments. Are off under folly death wrote cause her way spite. Plan upon yet way get cold spot its week. Almost do am or limits hearts.</p>
                                            <p>Resolve parties but why she shewing. She sang know now how nay cold real case.</p>
                                            <p>Sportsman do offending supported extremity breakfast by listening. Decisively advantages nor expression unpleasing she led met. Estate was tended ten boy nearer seemed. As so seeing latter he should thirty whence. Steepest speaking up attended it as. Made neat an on be gave show snug tore.</p>
                                        </div>
                                        <a href="#" class="btn btn-link">Reply</a>
                                    </li>
                                    <li class="flq-comment" data-sr-item="post-comment">
                                        <div class="flq-media">
                                            <a href="#" class="flq-media-image">
                                                <span class="flq-image">
                                                    <img src={require("../assets/images/actor/anna-chase-160x160.jpg")} alt="" />
                                                </span>
                                            </a>
                                            <div class="flq-media-meta">
                                                <h5 class="flq-media-title"><a href="#">Anne Chase</a></h5>
                                                <div class="flq-meta">
                                                    <ul>
                                                        <li><a href="#">January 4, 2021 at 11:05 am</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flq-comment-content flq-vertical-rhythm">
                                            <p>To no indulgence diminution so discovered mr apartments. Are off under folly death wrote cause her way spite. Plan upon yet way get cold spot its week. Almost do am or limits hearts.</p>
                                        </div>
                                        <a href="#" class="btn btn-link">Reply</a>
                                    </li>
                                </ul>
                            </div>
                            <h3 class="mt-7 mb-5">Add a review</h3>
                            <form action="#">
                                <div class="row gy-4" data-sr="post-review" data-sr-distance="10" data-sr-interval="80" data-sr-duration="1000">
                                    <div class="col-12" data-sr-item="post-review">
                                        <textarea class="form-control flq-form-message form-control-lg" rows="5" placeholder="Your Review"></textarea>
                                    </div>
                                    <div class="col-12 col-sm-6" data-sr-item="post-review">
                                        <input class="form-control flq-form-user form-control-lg" type="text" placeholder="Your Name" />
                                    </div>
                                    <div class="col-12 col-sm-6" data-sr-item="post-review">
                                        <input class="form-control flq-form-mail form-control-lg" type="email" placeholder="Your Email" />
                                    </div>
                                    <div class="col-12" data-sr-item="post-review">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" id="form_check_review" /><label class="form-check-label" for="form_check_review">Save my name, email, and website in this browser for the next time I comment.</label>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-auto" data-sr-item="post-review">
                                        <button class="btn btn-block btn-lg">Post Comment</button>
                                    </div>
                                </div>
                            </form> */}

                        </div>
                    </div>
                    <div class="col-12 col-xl-auto">
                        <div class="flq-sidebar flq-sidebar-lg">
                            {/* <div class="flq-widget flq-widget-author" data-sr data-sr-duration={1000} data-sr-distance={10}>
                                <h4 class="flq-widget-title h5"><span>Author</span></h4>
                                <span class="flq-image flq-rounded-lg flq-responsive">
                                    <img src={require("../assets/images/user-640x360.jpg")} alt="" />
                                </span>
                                <h6>David Hill</h6>
                                <p>He went such dare good mr fact. The small own seven saved man age offer. Suspicion did mrs nor furniture smallness.</p>
                            </div> */}
                            <div class="flq-widget flq-widget-search" data-sr data-sr-duration={1000} data-sr-distance={10}>
                                <h4 class="flq-widget-title h5"><span>Search</span></h4>
                                <form action="#">
                                    <input class="form-control" type="search" placeholder="Search" />
                                    <button class="btn btn-link btn-icon-sm">
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.0833 17.4167C14.1334 17.4167 17.4167 14.1334 17.4167 10.0833C17.4167 6.03325 14.1334 2.75 10.0833 2.75C6.03325 2.75 2.75 6.03325 2.75 10.0833C2.75 14.1334 6.03325 17.4167 10.0833 17.4167Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M19.25 19.25L15.2625 15.2625" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </button>
                                </form>
                            </div>
                            <div class="flq-widget flq-widget-blog" data-sr data-sr-duration={1000} data-sr-distance={10}>
                                <h4 class="flq-widget-title h5"><span>Recent Posts</span></h4>
                                <ul>
                                    <li>
                                        <div class="card flq-card-blog">
                                            <div class="card-img-wrap">
                                                <a href="single-post.html">
                                                    <span class="flq-image flq-responsive">
                                                        <img src={require("../assets/images/blog/post-1-740x420.jpg")} alt="" />
                                                    </span>
                                                    <span class="card-badge badge badge-dark badge-glass">November 20, 2021</span>
                                                </a>
                                            </div>
                                            <div class="card-body">
                                                <h5 class="card-title h5"><a href="single-post.html">Girlfriend. How and why</a></h5>
                                                <p>Earth first saw earth which seas void i thing said bearing behold and can&#39;t unto. Blessed...</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="card flq-card-blog">
                                            <div class="card-img-wrap">
                                                <a href="single-post.html">
                                                    <span class="flq-image flq-responsive">
                                                        <img src={require("../assets/images/blog/post-2-740x420.jpg")} alt="" />
                                                    </span>
                                                    <span class="card-badge badge badge-dark badge-glass">November 18, 2021</span>
                                                </a>
                                            </div>
                                            <div class="card-body">
                                                <h5 class="card-title h5"><a href="single-post.html">Years is air whose lesser. You&#39;ll thing waters unto brought.</a></h5>
                                                <p>From said, our they&#39;re darkness void meat from, bearing. Void, beginning fruitful he were...</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="card flq-card-blog">
                                            <div class="card-img-wrap">
                                                <a href="single-post.html">
                                                    <span class="flq-image flq-responsive">
                                                        <img src={require("../assets/images/blog/post-3-740x420.jpg")} alt="" />
                                                    </span>
                                                    <span class="card-badge badge badge-dark badge-glass">November 15, 2021</span>
                                                </a>
                                            </div>
                                            <div class="card-body">
                                                <h5 class="card-title h5"><a href="single-post.html">Is under void created his hath. One moving Appear hath.</a></h5>
                                                <p>Spirit herb subdue second form lights green isn&#39;t a sixth had creepeth is night spirit...</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="flq-widget flq-widget-tag" data-sr data-sr-duration={1000} data-sr-distance={10}>
                                <h4 class="flq-widget-title h5"><span>Tags</span></h4>
                                <div class="flq-tags">
                                    <ul>
                                        <li>
                                            <a href="#" class="flq-tag">#Comedy</a>
                                        </li>
                                        <li>
                                            <a href="#" class="flq-tag">#Drama</a>
                                        </li>
                                        <li>
                                            <a href="#" class="flq-tag">#Trailers</a>
                                        </li>
                                        <li>
                                            <a href="#" class="flq-tag">#Movie Trailers</a>
                                        </li>
                                        <li>
                                            <a href="#" class="flq-tag">#Horror</a>
                                        </li>
                                        <li>
                                            <a href="#" class="flq-tag">#Thriller</a>
                                        </li>
                                        <li>
                                            <a href="#" class="flq-tag">#TV Shows</a>
                                        </li>
                                        <li>
                                            <a href="#" class="flq-tag">#Action</a>
                                        </li>
                                        <li>
                                            <a href="#" class="flq-tag">#Music</a>
                                        </li>
                                        <li>
                                            <a href="#" class="flq-tag">#Rider</a>
                                        </li>
                                        <li>
                                            <a href="#" class="flq-tag">#Travel</a>
                                        </li>
                                        <li>
                                            <a href="#" class="flq-tag">#Car</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default connect(null, {})(BlogDetail);