import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { update_page_meta } from "../actions/page/header";
import PageTitle from "../components/common/PageTitle";
import { Albums } from "../data/albums";

const MusicList = ({ update_page_meta }) => {
    const Songs = Albums[0].songs;
    const songs = Songs.map((song, index) => {
        const thumbnail = song.thumbnails.find(item => item.size === "390x440")
        return (
            <div key={index} class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 flq-isotope-item">
                <div data-sr-item="tvshow-item">
                    <div class="card flq-card-blog">
                        <div class="card-img-wrap">
                            <Link to={`/music/${song.slug}/`}>
                                <span class="flq-image flq-rounded-lg flq-responsive flq-responsive-sm-3x4">
                                    <img src={thumbnail.img_src} alt={thumbnail.alt} />
                                </span>
                                <span class="card-badge badge badge-dark badge-glass flq-color-white badge-sm">{song.duration}</span>
                            </Link>
                        </div>
                        <div class="card-body">
                            <h5 class="card-title h6"><Link to={`/music/${song.slug}/`}>{song.title}</Link></h5>
                            <div class="flq-meta flq-meta-sm">
                                <ul>
                                    <li>
                                        <a href="#" class="card-year">{song.year}</a>
                                    </li>
                                    {/* <li>
                                        <a href="#" class="card-category">Romance</a>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    })
    update_page_meta({
        title: "Music | Gurus TV",
        description: "Music produced by Gurus TV To spread Sree Narayana Guru's principles to the world.",
        ogimgurl: Songs[0].thumbnails.find(item => item.size === "3840x2160").img_src,
        ogimgwidth: "3840",
        ogimgheight: "2160"
    })
    return (
        <>
            <PageTitle title="Music" breadcrumbs={[
                {
                    title: "Home",
                    loc: "/"
                },
                {
                    title: "Music",
                    loc: "/music/"
                }
            ]} />
            <div class="py-7">
                <div class="container flq-isotope" data-sr="tvshow-item" data-sr-interval="80" data-sr-duration="1000" data-sr-distance="10">
                    {/* <div class="row align-items-center gy-4 mb-5">
                        <div class="col-12 col-md">
                            <nav>
                                <ul class="nav nav-tabs flq-isotope-options" data-sr="tvshow-options" data-sr-interval="80" data-sr-duration="1000" data-sr-distance="10">
                                    <li class="nav-item" data-sr-item="tvshow-options">
                                        <a href="#" data-filter="all" class="nav-link active"><span class="nav-link-name">All</span></a>
                                    </li>
                                    <li class="nav-item" data-sr-item="tvshow-options">
                                        <a href="#" data-filter="romance" class="nav-link"><span class="nav-link-name">Inactive navlink</span></a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div class="col-12 col-md-auto" data-sr data-sr-delay="160" data-sr-duration="1000" data-sr-distance="10">
                            <select class="form-select">
                                <option>By popularity</option>
                                <option>By rating</option>
                                <option>By date</option>
                                <option>By name</option>
                            </select>
                        </div>
                    </div> */}
                    <div class="row gy-5 justify-content-center flq-isotope-grid">
                        {songs}
                    </div>
                    {/* <div class="text-center mt-5" data-sr-item="tvshow-item"><button class="btn btn-outline btn-white">Show More</button></div> */}
                </div>
            </div>
        </>
    )
}

export default connect(null, { update_page_meta })(MusicList);