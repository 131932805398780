import React from "react";
import { Route, Routes } from 'react-router-dom';
import MusicList from "../pages/MusicList";
import MusicDetail from "../pages/MusicDetail";

const MusicRoutes = () => {
    return (
        <Routes>
            <Route path="/music/" element={<MusicList />} />
            <Route path="/music/:songSlug/" element={<MusicDetail/>} />
        </Routes>
    )
}

export default MusicRoutes;