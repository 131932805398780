import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { login } from "../../actions/accounts/accounts";
import { useIsAuthenticated } from "../../reducers/accounts/accountsReducer";
import Fancybox from "../FancyBox";
import { Fancybox as NativeFancybox } from "@fancyapps/ui/dist/fancybox.esm.js";

const SigninPopup = ({ login }) => {
    const isAuthenticated = useIsAuthenticated();
    let navigate = useNavigate();
    const [Form, setForm] = useState({
        email: null,
        password: null
    })
    const updateForm = (e) => {
        setForm({...Form, [e.target.name]: e.target.value});
    }
    const validate = () => {
        if (!Form.email || Form.email.length <= 0 || !Form.password || Form.password.length <= 0){
            window.alert("Required information missing");
            return false;
        }
        return true;
    }
    
    const submitForm = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let valid = validate();
        if (!valid){
            return;
        }
        login(Form.email, Form.password);
    }
    return (
        <div className="flq-fancybox-content-signin fancybox-content" id="flq_popup_signin">
            <div className="flq-signin">
                <div className="flq-fancybox-head">
                    <div className="container-fluid">
                        <Link to="/" className="flq-fancybox-brand me-auto">
                            <img src={require("../../assets/images/logo.png")} className="flq-logo" alt="" />
                        </Link>
                        <button className="flq-fancybox-close btn btn-link ms-4" data-fancybox-close>
                            <span data-fancybox-close></span>
                        </button>
                    </div>
                </div>
                <div className="flq-fancybox-body pb-6">
                    <form action="#" className="flq-signin-content">
                        <h4 className="mb-4 pb-1 text-center">Login</h4>
                        <div className="row justify-content-between gy-4">
                            <div className="col-12">
                                <input className="form-control flq-form-mail flq-form-translucent" type="email"
                                    onChange={updateForm}
                                    name="email"
                                    value={Form.email} placeholder="Email" />
                            </div>
                            <div className="col-12">
                                <input className="form-control flq-form-lock flq-form-translucent" type="password"
                                    onChange={updateForm}
                                    name="password"
                                    value={Form.password} placeholder="Password" />
                            </div>
                            {/* <div className="col-auto">
                                <div className="form-check flq-form-translucent">
                                    <input className="form-check-input" type="checkbox" id="signinRememberMe" /><label className="form-check-label" for="signinRememberMe">Remember Me</label>
                                </div>
                            </div> */}
                            {/* <div className="col-auto">
                                <Link href="#" className="flq-color-meta flq-color-title-hover">Lost Password</Link>
                            </div> */}
                            <div className="col-12">
                                <button className="btn btn-block" onClick={submitForm}>Login</button>
                            </div>
                            <div className="col-12 mt-3 text-center">
                                {/* <Link to="" className="btn btn-link" data-fancybox="signup" data-src="#signup" data-base-className="flq-fancybox-signup" data-keyboard="false" data-animation-duration="1000" data-auto-focus="false" data-touch="false" data-close-existing="false" data-small-btn="false" data-toolbar="false"> Sign Up </Link> */}
                                <Fancybox options={
                                        {
                                            infinite: false, 
                                            mainClass: "flq-fancybox-open flq-fancybox-signup",
                                            keyboard: false,
                                            toolbar: false,
                                            closeExisting: true,
                                            touch: false,
                                            autoFocus: false,
                                            animated: true,
                                            dragToClose: false
                                        }
                                    }>
                                        <Link to="" className="btn btn-link" data-fancybox="signup" data-src="#signup" data-base-className="flq-fancybox-signup" data-keyboard="false" data-animation-duration="1000" data-auto-focus="false" data-touch="false" data-close-existing="false" data-small-btn="false" data-toolbar="false"> Sign Up </Link>
                                </Fancybox>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default connect(null, { login })(SigninPopup)