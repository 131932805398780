import React from "react";
import { connect } from "react-redux";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Thumbs, Pagination, Navigation, Controller, Autoplay } from 'swiper';
import { Link } from "react-router-dom";
import { Albums } from "../../data/albums";

const Slide = ({slide}) => {
    const thumbnail = slide.thumbnails.find(item => item.size === "1290x1290");
    return (
        <SwiperSlide>
            <img src={thumbnail.img_src} alt={thumbnail.alt} />
        </SwiperSlide>
    )
}

const NewReleases = ({}) => {
    const songs = Albums[0].songs;
    const slides = songs.map((song, index) => {
        return (
            <Slide key={index} slide={song} />
        )
    })
    return (
        <>
            <div className="flq-swiper-wrapper my-7" data-sr="new-releases" data-sr-interval="100" data-sr-duration="1000" data-sr-distance="10">
                <div className="container mb-5" data-sr-item="new-releases">
                    <h2>New Releases</h2>
                </div>   
                <div className="flq-swiper-effect-touch">
                    <Swiper className="swiper-container container"
                        touchRatio={0.8}
                        spaceBetween={30}
                        slidesPerView={1}
                        breakpoints={{
                            636: {
                                slidesPerView: 2
                            },
                            1072: {
                                slidesPerView: 3
                            },
                            1280: {
                                slidesPerView: 4
                            }
                        }}
                        onSlideChange={() => console.log('slide change')}
                        onSwiper={(swiper) => console.log(swiper)}
                        navigation={{
                            nextEl: '.new-releases-button-next',
                            prevEl: '.new-releases-button-prev',
                        }}
                        pagination={{
                            el: '.new-releases-pagination',
                            type: 'fraction',
                        }}
                        modules={[Pagination, Navigation, Controller]}
                        >
                            {songs.map((song, index) => {
                                const thumbnail = song.thumbnails.find(item => item.size === "1290x1290");
                                return <SwiperSlide key={index}>
                                    <div className="card flq-card-blog">
                                        <div className="card-img-wrap" 
                                        // style={{background: `url(${thumbnail.img_src}) center center / contain no-repeat fixed`}}
                                        >
                                            <a data-fancybox={`song-gallery-${Date.now().toString(36) + Math.floor(Math.pow(10, 12) + Math.random() * 9*Math.pow(10, 12)).toString(36)}`} href={song.youtube_link}>
                                                <span className="flq-image flq-rounded-xl flq-responsive flq-responsive-sm-3x4">
                                                    <img src={thumbnail.img_src} alt={thumbnail.alt} />
                                                </span>
                                                <span className="card-badge badge badge-dark badge-glass flq-color-white">{song?.duration}</span>
                                            </a>
                                        </div>
                                        <div className="card-body">
                                            <h5 className="card-title">
                                                <a data-fancybox={`song-gallery-${Date.now().toString(36) + Math.floor(Math.pow(10, 12) + Math.random() * 9*Math.pow(10, 12)).toString(36)}`} href={song.youtube_link}>
                                                    {song.title}
                                                </a>
                                                {/* <Link to={song.url}>{song.title}</Link> */}
                                            </h5>
                                            <div className="flq-meta flq-meta-sm">
                                                <ul>
                                                    <li>
                                                        <Link to="" className="card-year">{song.year}</Link>
                                                    </li>
                                                    {/* <li>
                                                        <Link to="" className="card-year">{song?.duration}</Link>
                                                    </li> */}
                                                    {/* <li>
                                                        <Link to={slide.category.url} className="card-category">{slide.category.name}</Link>
                                                    </li> */}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            })}
                    </Swiper>
                </div> 
                <div className="container mt-5">
                    <div className="row align-items-center justify-content-between gx-5">
                        <div className="col-auto" data-sr-item="new-releases">
                            <div className="new-releases-pagination flq-swiper-pagination-custom text-white" style={{bottom: 0}}></div>
                        </div>
                        <div className="col d-none d-sm-block" data-sr-item="new-releases">
                            <hr />
                        </div>
                        <div className="col-auto" data-sr-item="new-releases">
                            <div className="
                                new-releases-button-prev
                                flq-swiper-button-prev
                                btn btn-sm btn-dark btn-active btn-square btn-icon-sm
                                me-1
                                " data-sr-item="related">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15 18L9 12L15 6" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>
                            <div className="
                                new-releases-button-next
                                flq-swiper-button-next
                                btn btn-sm btn-dark btn-active btn-square btn-icon-sm
                                " data-sr-item="related">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9 6L15 12L9 18" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
        </>
    )
}

export default connect(null, {})(NewReleases);