import React from "react";
import { Route, Routes } from 'react-router-dom';
import BlogList from "../pages/BlogList";
import BlogDetail from "../pages/BlogDetail";

const BlogRoutes = () => {
    return (
        <Routes>
            <Route path="/read/" element={<BlogList />} />
            <Route path="/read/:blogSlug/" element={<BlogDetail/>} />
        </Routes>
    )
}

export default BlogRoutes;