import { BrowserRouter as Router } from 'react-router-dom';
import BrowserRoutes from './routes/BrowserRoutes';
import ScrollToTop from './components/ScrollToTop';
import Layout from './hocs/Layout';
import { Provider } from "react-redux";
import store from './store';
import Head from './hocs/Head';

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Head />
        <Layout>
          <ScrollToTop />
          <BrowserRoutes />
        </Layout>
      </Router>
    </Provider>
  );
}

export default App;
