import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/bootstrap-custom.min.css';
import 'swiper/css/bundle';
import "@fancyapps/ui/dist/fancybox.css";
import '../assets/css/style.css';
import Navbar from '../components/common/Navbar';
import Preloader from '../components/common/Preloader';
import ScrollTopButton from '../components/common/ScrollTopButton';
import NavbarIcons from '../components/common/NavbarIcons';
import Footer from '../components/common/Footer';
import MobileNavbar from '../components/common/MobileNavbar';
import MobileUserDropDown from '../components/common/MobileUserDropDown';
import SearchPopup from '../components/common/SearchPopup';
import SigninPopup from '../components/common/SigninPopup';
import SignupPopup from '../components/common/SignupPopup';
import OffCanvas from '../components/common/OffCanvas';
import { useIsAuthenticated, useUser } from '../reducers/accounts/accountsReducer';
import { checkAuthenticated, getMyProfile } from '../actions/accounts/accounts';
import CSRFToken from '../components/CSRFToken';

const Layout = ({ children, checkAuthenticated, getMyProfile }) => {
    const isAuthenticated = useIsAuthenticated();
    const user = useUser();
    useEffect(() => {
        document.body.classList.add("flq-navbar-icons-existence", "flq-cursor-enabled");
    }, []);
    useEffect(() => {
        checkAuthenticated();
    }, []);
    useEffect(() => {
        if(isAuthenticated && !user){
            getMyProfile();
        }
    }, [isAuthenticated]);
    return (
        <>
            {/* <Preloader /> */}
            <CSRFToken />
            <Navbar />
            <div className='content-wrap'>
                {children}
                <Footer />
            </div>
            

            <NavbarIcons />
            <ScrollTopButton />
            <MobileNavbar />
            <MobileUserDropDown />
            <SearchPopup />
            <SigninPopup />
            <SignupPopup />
            <OffCanvas />

            <div className="flq-cursor" style={{transform: "matrix(1, 0, 0, 1, 988, 164) translate3d(0px, 0px, 0px)"}}><span></span></div>
        </>
    )
}

export default connect(null, { checkAuthenticated, getMyProfile })(Layout)