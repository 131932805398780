import React from "react";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";

const Sidebar = ({}) => {
    var location = useLocation();
    console.log(location.pathname.split("/")[0], location.pathname.split("/")[1]);
    return (
        <>
            <div className="col-12 col-xl-auto">
                <div className="flq-sidebar flq-sidebar-lg">
                    <div className="flq-account-navbar">
                        <nav>
                            <ul className="nav nav-dots flex-column gy-3">
                                <li className={`nav-item ${location.pathname.split("/")[1] === 'profile' ? 'active' : ''}`}>
                                    <Link to="/profile/" className="nav-link">
                                        <span className="nav-link-name">Profile</span>
                                    </Link>
                                </li>
                                {/* <li className={`nav-item ${location.pathname.split("/")[1] === 'my-favorites' ? 'active' : ''}`}>
                                    <Link to="/my-favorites/" className="nav-link">
                                        <span className="nav-link-name">My Favorites</span>
                                    </Link>
                                </li> */}
                                {/* <li className="nav-item">
                                    <a href="account-reviews.html" className="nav-link">
                                        <span className="nav-link-name">Reviews &amp; Comments</span>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="account-edit.html" className="nav-link">
                                        <span className="nav-link-name">Account Edit</span>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="account-notifications.html" className="nav-link">
                                        <span className="nav-link-name">Notifications</span>
                                    </a>
                                </li> */}
                                {/* <li className="nav-item">
                                    <Link to="" className="nav-link">
                                        <span className="nav-link-name">Logout</span>
                                    </Link>
                                </li> */}
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </>
    )
}

export default connect(null, {})(Sidebar);