import { PAGE_LOADED } from '../../actions/page/types';
import { useSelector } from 'react-redux'

const initialState = {
    pageMeta: {
        title: "Gurus Music",
        description: "Narayana Guru (ca. 1854–1928), also seen as Sree Narayana Guru, was a Hindu sant, sadhu and social reformer of India."
    }
};

export const pagemetaReducer = (state=initialState, action) => {
    const {type, payload} = action;
    switch(type){
        case PAGE_LOADED:
            return {
                ...state,
                pageMeta: {
                    ...state.pageMeta,
                    ...payload
                }
            };
        default:
            return state;
    }
}

export function usePageMeta() {
    return useSelector((state) => {
        return state.page.pageMeta;
    })
}