import { Albums } from "./albums"

export const getSong = (slug) => {
    let song = null;
    for (var i=0; i<Albums.length; i++){
        song = Albums[i].songs.find(item => item.slug === slug)
        if (song)
            break;
    }
    return song;
}