import React from "react";
import { connect } from "react-redux";
import { usePageMeta } from "../reducers/page/pagemetaReducer";

const Head = ({}) => {
    const pageMeta = usePageMeta();
    document.title = pageMeta.title;
    document.querySelector('meta[name="description"]').setAttribute("content", pageMeta.description);

    document.querySelector('meta[property="og:image"]').setAttribute("content", process.env.REACT_APP_BASE_URL + pageMeta.ogimgurl);
    document.querySelector('meta[property="og:title"]').setAttribute("content", pageMeta.title);
    document.querySelector('meta[property="og:description"]').setAttribute("content", pageMeta.description);
    document.querySelector('meta[property="og:image:width"]').setAttribute("content", pageMeta.ogimgwidth ? pageMeta.ogimgwidth : "1200");
    document.querySelector('meta[property="og:image:height"]').setAttribute("content", pageMeta.ogimgheight ? pageMeta.ogimgheight : "630");
    
    return (
        <></>
    )
}

export default connect(null, {})(Head);