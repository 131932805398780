import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <footer className="flq-footer flq-background py-7" data-sr="footer" data-sr-interval="60" data-sr-duration="1000" data-sr-distance="10">
            <div className="flq-background-image">
                <span className="flq-image jarallax" data-speed={0.7}>
                    <img src={require("../../assets/images/footer-bg.jpg")} className="jarallax-img" alt="" />
                </span>
            </div>
            <div className="flq-background-overlay" style={{backgroundColor: "hsla(var(--flq-color-background), .8)"}}></div>
            <div className="container">
                <div className="row gx-5 gy-5 mb-6 justify-content-center justify-content-sm-start">
                    <div className="col-md-9 col-lg-3 text-center text-sm-start" data-sr-item="footer"
                        style={{ visibility: "visible",
                                opacity: 1,
                                transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                                transition: "opacity 1s cubic-bezier(0.5, 0, 0, 1) 0s, transform 1s cubic-bezier(0.5, 0, 0, 1) 0s"}}>
                        <Link to="/">
                            <img src={require("../../assets/images/logo.png")} className="flq-logo" alt="" />
                        </Link>
                        <p className="mt-1 pt-1">Narayana Guru (ca. 1854–1928), also seen as Sree Narayana Guru, was a Hindu sant, sadhu and social reformer of India.</p>
                    </div>
                    <div className="col-lg-1 col-xl d-none d-lg-block"></div>
                    {/* <div className="col-12 col-md-4 col-lg col-xl-2">
                        <nav>
                            <ul className="nav flex-column gy-3">
                                <li className="nav-item" data-sr-item="footer">
                                    <a href="#" className="nav-link">FAQ</a>
                                </li>
                                <li className="nav-item" data-sr-item="footer">
                                    <a href="#" className="nav-link">Investor Relations</a>
                                </li>
                                <li className="nav-item" data-sr-item="footer">
                                    <a href="#" className="nav-link">Privacy</a>
                                </li>
                                <li className="nav-item" data-sr-item="footer">
                                    <a href="#" className="nav-link">Speed Test</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div className="col-12 col-md-4 col-lg col-xl-2">
                        <nav>
                            <ul className="nav flex-column gy-3">
                                <li className="nav-item" data-sr-item="footer">
                                    <a href="#" className="nav-link">Help Center</a>
                                </li>
                                <li className="nav-item" data-sr-item="footer">
                                    <a href="#" className="nav-link">Jobs</a>
                                </li>
                                <li className="nav-item" data-sr-item="footer">
                                    <a href="#" className="nav-link">Cookie Preferences</a>
                                </li>
                                <li className="nav-item" data-sr-item="footer">
                                    <a href="#" className="nav-link">Legal Notices</a>
                                </li>
                            </ul>
                        </nav>
                    </div> */}
                    {/* <div className="col-12 col-md-4 col-lg col-xl-2">
                        <nav>
                            <ul className="nav flex-column gy-3">
                                <li className="nav-item" data-sr-item="footer">
                                    <a href="#" className="nav-link">Account</a>
                                </li>
                                <li className="nav-item" data-sr-item="footer">
                                    <a href="#" className="nav-link">Ways to Watch</a>
                                </li>
                                <li className="nav-item" data-sr-item="footer">
                                    <a href="#" className="nav-link">Corporate Information</a>
                                </li>
                                <li className="nav-item" data-sr-item="footer">
                                    <a href="#" className="nav-link">Contact Us</a>
                                </li>
                            </ul>
                        </nav>
                    </div> */}
                </div>
                <div className="row gy-4 justify-content-between" data-sr="footer-copyright" data-sr-interval="100" data-sr-delay="200" data-sr-duration="1000" data-sr-distance="10">
                    <div className="col-12 col-sm-auto text-center text-sm-start" data-sr-item="footer-copyright" 
                        style={{ visibility: "visible",
                                opacity: 1,
                                transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                                transition: "opacity 1s cubic-bezier(0.5, 0, 0, 1) 0s, transform 1s cubic-bezier(0.5, 0, 0, 1) 0s"}}>
                        <p>© {new Date().getFullYear() > 2023 && '2023 - '}{new Date().getFullYear()} Guru's Music. Developed by <a href="https://www.ampliquity.com/" target="_blank">Ampliquity Technologies</a></p>
                    </div>
                    <div className="col-12 col-sm-auto text-center text-sm-start" data-sr-item="footer-copyright">
                        <div className="flq-social text-center">
                            <ul className="gy-4 gx-4">
                                <li>
                                    <a href="https://www.youtube.com/@gurustvofficial" target="_blank" className="flq-social-link" data-sr-item="footer-copyright">
                                        <svg height="24" width="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 461.001 500" fill="none">
                                            <g>
                                                <path fill="currentColor" d="M365.257,67.393H95.744C42.866,67.393,0,110.259,0,163.137v134.728
                                                    c0,52.878,42.866,95.744,95.744,95.744h269.513c52.878,0,95.744-42.866,95.744-95.744V163.137
                                                    C461.001,110.259,418.135,67.393,365.257,67.393z M300.506,237.056l-126.06,60.123c-3.359,1.602-7.239-0.847-7.239-4.568V168.607
                                                    c0-3.774,3.982-6.22,7.348-4.514l126.06,63.881C304.363,229.873,304.298,235.248,300.506,237.056z"></path>
                                            </g>
                                        </svg>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.facebook.com/gurustvofficial" target="_blank" className="flq-social-link" data-sr-item="footer-copyright">
                                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M23.625 12C23.625 5.57812 18.4219 0.375 12 0.375C5.57812 0.375 0.375 5.57812 0.375 12C0.375 17.8022 4.62609 22.6116 10.1836 23.4844V15.3605H7.23047V12H10.1836V9.43875C10.1836 6.52547 11.918 4.91625 14.5744 4.91625C15.8466 4.91625 17.1769 5.14313 17.1769 5.14313V8.0025H15.7106C14.2669 8.0025 13.8164 8.89875 13.8164 9.81797V12H17.0405L16.5248 15.3605H13.8164V23.4844C19.3739 22.6116 23.625 17.8022 23.625 12Z" fill="currentColor" />
                                        </svg>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/gurustv/" target="_blank" className="flq-social-link" data-sr-item="footer-copyright">
                                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.22506 0.450134C3.03566 0.450134 0.450134 3.03566 0.450134 6.22506V17.7749C0.450134 20.9643 3.03566 23.5498 6.22506 23.5498H17.7749C20.9643 23.5498 23.5498 20.9643 23.5498 17.7749V6.22506C23.5498 3.03566 20.9643 0.450134 17.7749 0.450134H6.22506ZM5.5834 12C5.5834 15.5437 8.45621 18.4166 12 18.4166C15.5437 18.4166 18.4166 15.5437 18.4166 12C18.4166 8.45621 15.5437 5.5834 12 5.5834C8.45621 5.5834 5.5834 8.45621 5.5834 12ZM12 15.8499C14.1263 15.8499 15.8499 14.1263 15.8499 12C15.8499 9.87365 14.1263 8.15003 12 8.15003C9.87366 8.15003 8.15004 9.87365 8.15004 12C8.15004 14.1263 9.87366 15.8499 12 15.8499ZM19.6999 4.30008C19.6999 5.00883 19.1253 5.5834 18.4166 5.5834C17.7078 5.5834 17.1333 5.00883 17.1333 4.30008C17.1333 3.59133 17.7078 3.01677 18.4166 3.01677C19.1253 3.01677 19.6999 3.59133 19.6999 4.30008Z" fill="currentColor" />
                                        </svg>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default connect(null, {})(Footer);