import { useSelector } from 'react-redux'
import { LOGIN_SUCCESS, IS_AUTHENTICATED, LOGOUT_SUCCESS, SELF_USER_LOADED } from '../../actions/accounts/types';

const initialState = {
    isAuthenticated: false,
    user: null
};

export const accountsReducer = (state=initialState, action) => {
    const {type, payload} = action;
    switch(type){
        case IS_AUTHENTICATED:
            return {
                ...state,
                isAuthenticated: payload
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                user: payload
            };
        case LOGOUT_SUCCESS:
            return {
                ...state,
                isAuthenticated: false,
                user: null
            };
        case SELF_USER_LOADED:
            return {
                ...state,
                user: payload
            };
        default:
            return state;
    }
}

export function useIsAuthenticated() {
    return useSelector((state) => {
        return state.accounts.isAuthenticated;
    })
}

export function useUser() {
    return useSelector((state) => {
        return state.accounts.user;
    })
}