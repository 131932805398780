import axios from 'axios';
import { JSON_CONTENT_FORM_CONFIG } from '../Config';
import {
    IS_AUTHENTICATED,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT_SUCCESS,
    SELF_USER_LOADED
} from "./types";

export const checkAuthenticated = () => async dispatch => {
    const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/is_authenticated/`, JSON_CONTENT_FORM_CONFIG);
    dispatch({
        type: IS_AUTHENTICATED,
        payload: res.data.is_authenticated
    });
};


export const getMyProfile = () => async dispatch => {
    const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/accounts/get-my-profile/`, JSON_CONTENT_FORM_CONFIG);
    if (res.data.success){
        dispatch({
            type: SELF_USER_LOADED,
            payload: res.data.user
        });
    }
};

export const signup = (email, password, first_name, last_name, phone) => async dispatch => {
    const body = JSON.stringify({email, password, first_name, last_name, phone});
    const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/accounts/signup/`, body, JSON_CONTENT_FORM_CONFIG);
    if (res.data.success){
        window.location.reload();
        // dispatch({
        //     type: LOGIN_SUCCESS,
        //     payload: res.data.user
        // });
    }
};

export const login = (email, password) => async dispatch => {
    const body = JSON.stringify({email, password});
    const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/accounts/login/`, body, JSON_CONTENT_FORM_CONFIG);
    if (res.data.success){
        window.location.reload();
        // dispatch({
        //     type: LOGIN_SUCCESS,
        //     payload: res.data.user
        // });
    }
    else{
        dispatch({
            type: LOGIN_FAIL
        });
    }
};

export const logout = () => async dispatch => {
    const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/accounts/logout/`, {}, JSON_CONTENT_FORM_CONFIG);
    if (res.data.success){
        dispatch({
            type: LOGOUT_SUCCESS,
            payload: res.data.user
        });
    }
};
