import React from "react";
import { Link } from "react-router-dom";

const ScrollTopButton = () => {
    return (
        <div className="flq-scroll-top-wrapper">
            <Link className="flq-scroll-top-button flq-anchor" to="#">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 6L15 12L9 18" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </Link>
            <div className="flq-scroll-progress flq-scroll-progress-circle">
                <svg>
                    <circle stroke="currentColor" r="0" fill="none" />
                </svg>
            </div>
        </div>
    )
}

export default ScrollTopButton;