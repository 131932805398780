import React from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom'

/* TODO */
// 1. flq-image-ticker-inner: check visibility and opacity to make the slider visible



const PageTitle = ({ title, breadcrumbs }) => {
    const breadcrumbListItems = breadcrumbs.map((item, index) => {
        if (breadcrumbs.length === index + 1)
            return (
                <li className="breadcrumb-item active" aria-current="page">{item.title}</li>
            )
        else{
            return (<li className="breadcrumb-item"><Link to={item.loc}>{item.title}</Link></li>)
        }
    })
    return (
        <>
            <div className="flq-background py-7">
                <div className="flq-background-ticker">
                    <div className="flq-image-ticker mb-3 flq-image-ticker-ready" data-gap={20} data-pixels-per-second={20}>
                        <div className="flq-image-ticker-inner">
                            <div className="flq-image-ticker-item">
                                <span className="flq-image">
                                    <img src={require("../../assets/images/background-ticker/1.webp")} alt="" />
                                </span>
                            </div>
                            <div className="flq-image-ticker-item">
                                <span className="flq-image">
                                    <img src={require("../../assets/images/background-ticker/2.webp")} alt="" />
                                </span>
                            </div>
                            <div className="flq-image-ticker-item">
                                <span className="flq-image">
                                    <img src={require("../../assets/images/background-ticker/13.webp")} alt="" />
                                </span>
                            </div>
                            <div className="flq-image-ticker-item">
                                <span className="flq-image">
                                    <img src={require("../../assets/images/background-ticker/3.webp")} alt="" />
                                </span>
                            </div>
                            <div className="flq-image-ticker-item">
                                <span className="flq-image">
                                    <img src={require("../../assets/images/background-ticker/11.webp")} alt="" />
                                </span>
                            </div>
                            <div className="flq-image-ticker-item">
                                <span className="flq-image">
                                    <img src={require("../../assets/images/background-ticker/12.webp")} alt="" />
                                </span>
                            </div>
                            <div className="flq-image-ticker-item">
                                <span className="flq-image">
                                    <img src={require("../../assets/images/background-ticker/4.webp")} alt="" />
                                </span>
                            </div>
                            <div className="flq-image-ticker-item">
                                <span className="flq-image">
                                    <img src={require("../../assets/images/background-ticker/5.webp")} alt="" />
                                </span>
                            </div>
                            <div className="flq-image-ticker-item">
                                <span className="flq-image">
                                    <img src={require("../../assets/images/background-ticker/10.webp")} alt="" />
                                </span>
                            </div>
                            <div className="flq-image-ticker-item">
                                <span className="flq-image">
                                    <img src={require("../../assets/images/background-ticker/9.webp")} alt="" />
                                </span>
                            </div>
                            <div className="flq-image-ticker-item">
                                <span className="flq-image">
                                    <img src={require("../../assets/images/background-ticker/6.webp")} alt="" />
                                </span>
                            </div>
                            <div className="flq-image-ticker-item">
                                <span className="flq-image">
                                    <img src={require("../../assets/images/background-ticker/8.webp")} alt="" />
                                </span>
                            </div>
                            <div className="flq-image-ticker-item">
                                <span className="flq-image">
                                    <img src={require("../../assets/images/background-ticker/7.webp")} alt="" />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="flq-image-ticker flq-image-ticker-ready" data-gap={20} data-pixels-per-second={20} data-inverted>
                        <div className="flq-image-ticker-inner">
                        <div className="flq-image-ticker-item">
                                <span className="flq-image">
                                    <img src={require("../../assets/images/background-ticker/1.webp")} alt="" />
                                </span>
                            </div>
                            <div className="flq-image-ticker-item">
                                <span className="flq-image">
                                    <img src={require("../../assets/images/background-ticker/2.webp")} alt="" />
                                </span>
                            </div>
                            <div className="flq-image-ticker-item">
                                <span className="flq-image">
                                    <img src={require("../../assets/images/background-ticker/13.webp")} alt="" />
                                </span>
                            </div>
                            <div className="flq-image-ticker-item">
                                <span className="flq-image">
                                    <img src={require("../../assets/images/background-ticker/3.webp")} alt="" />
                                </span>
                            </div>
                            <div className="flq-image-ticker-item">
                                <span className="flq-image">
                                    <img src={require("../../assets/images/background-ticker/11.webp")} alt="" />
                                </span>
                            </div>
                            <div className="flq-image-ticker-item">
                                <span className="flq-image">
                                    <img src={require("../../assets/images/background-ticker/12.webp")} alt="" />
                                </span>
                            </div>
                            <div className="flq-image-ticker-item">
                                <span className="flq-image">
                                    <img src={require("../../assets/images/background-ticker/4.webp")} alt="" />
                                </span>
                            </div>
                            <div className="flq-image-ticker-item">
                                <span className="flq-image">
                                    <img src={require("../../assets/images/background-ticker/5.webp")} alt="" />
                                </span>
                            </div>
                            <div className="flq-image-ticker-item">
                                <span className="flq-image">
                                    <img src={require("../../assets/images/background-ticker/10.webp")} alt="" />
                                </span>
                            </div>
                            <div className="flq-image-ticker-item">
                                <span className="flq-image">
                                    <img src={require("../../assets/images/background-ticker/9.webp")} alt="" />
                                </span>
                            </div>
                            <div className="flq-image-ticker-item">
                                <span className="flq-image">
                                    <img src={require("../../assets/images/background-ticker/6.webp")} alt="" />
                                </span>
                            </div>
                            <div className="flq-image-ticker-item">
                                <span className="flq-image">
                                    <img src={require("../../assets/images/background-ticker/8.webp")} alt="" />
                                </span>
                            </div>
                            <div className="flq-image-ticker-item">
                                <span className="flq-image">
                                    <img src={require("../../assets/images/background-ticker/7.webp")} alt="" />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flq-background-overlay" style={{backgroundColor: "hsla(var(--flq-color-black), 0.8)"}}></div>
                <div className="container text-center" data-sr="banner-text" data-sr-interval="100" data-sr-distance="10" data-sr-duration="1000">
                    <h1 className="display-5 mb-1" data-sr-item="banner-text"
                        style={{
                            visibility: "visible",
                            opacity: 1,
                            transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                            transition: "opacity 1s cubic-bezier(0.5, 0, 0, 1) 0s, transform 1s cubic-bezier(0.5, 0, 0, 1) 0s"
                        }}>
                            {title}
                    </h1>
                    <nav aria-label="breadcrumb" data-sr-item="banner-text"
                        style={{
                            visibility: "visible",
                            opacity: 1,
                            transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                            transition: "opacity 1s cubic-bezier(0.5, 0, 0, 1) 0s, transform 1s cubic-bezier(0.5, 0, 0, 1) 0s"
                        }}>
                            <ol className="breadcrumb flq-color-opacity">
                                {breadcrumbListItems}
                            </ol>
                    </nav>
                </div>
            </div>
        </>
    )
}

export default connect(null, {})(PageTitle);