import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { logout } from "../../actions/accounts/accounts";
import { useIsAuthenticated } from "../../reducers/accounts/accountsReducer";
import { Fancybox } from "@fancyapps/ui/dist/fancybox.esm.js";

const MobileUserDropDown = ({ logout }) => {
    const isAuthenticated = useIsAuthenticated();
    const logoutUser = () => {
        if (isAuthenticated)
            logout();
    }
    useEffect(() => {
        if (!isAuthenticated){
            let inst = Fancybox.getInstance("flq_popup_user");
            if (inst){
                inst.close();
            }
        }
    }, [isAuthenticated]);
    return (
        <nav className="flq-navbar-mobile fancybox-content" id="flq_popup_user">
            <div className="flq-fancybox-head">
                <div className="container-fluid">
                    <Link to="/" className="flq-fancybox-brand me-auto">
                        <img src={require("../../assets/images/logo.png")} className="flq-logo" alt="" />
                    </Link>
                    <button className="flq-fancybox-close btn btn-link ms-4" data-fancybox-close>
                        <span data-fancybox-close></span>
                    </button>
                </div>
            </div>
            <div className="container pt-4 pb-6">
                <div className="flq-fancybox-body row gy-6 gx-6">
                    <div className="col-12 col-lg">
                        <ul className="nav flex-column flq-navbar-nav accordion gy-3">
                            <li className="nav-item">
                                <Link to="/profile/" className="nav-link">
                                    <span className="nav-link-name">Profile</span>
                                </Link>
                            </li>
                            {/* <li className="nav-item">
                                <Link to="/my-favorites/" className="nav-link">
                                    <span className="nav-link-name">My Favorites</span>
                                </Link>
                            </li> */}
                            {/* <li className="nav-item">
                                <a href="account-reviews.html" className="nav-link">
                                    <span className="nav-link-name">Reviews & Comments</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="account-edit.html" className="nav-link">
                                    <span className="nav-link-name">Account Edit</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="account-notifications.html" className="nav-link">
                                    <span className="nav-link-name">Notifications</span>
                                </a>
                            </li> */}
                            <li className="nav-item">
                                <Link to="" onClick={logoutUser} className="nav-link">
                                    <span className="nav-link-name">Logout</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-auto">
                        <div className="flq-sidebar flq-sidebar-lg flq-fancybox-sidebar ms-lg-6">
                            <div className="flq-widget flq-widget-search">
                                <h4 className="flq-widget-title h5 flq-color-opacity"><span>Search</span></h4>
                                <form action="#">
                                    <input className="form-control flq-form-translucent" type="search" placeholder="Search" />
                                    <button className="btn btn-link btn-icon-sm">
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.0833 17.4167C14.1334 17.4167 17.4167 14.1334 17.4167 10.0833C17.4167 6.03325 14.1334 2.75 10.0833 2.75C6.03325 2.75 2.75 6.03325 2.75 10.0833C2.75 14.1334 6.03325 17.4167 10.0833 17.4167Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M19.25 19.25L15.2625 15.2625" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </button>
                                </form>
                            </div>
                            <div className="flq-widget flq-widget-tag">
                                <h4 className="flq-widget-title h5 flq-color-opacity"><span>Tags</span></h4>
                                <div className="flq-tags flq-tags-translucent">
                                    <ul>
                                        <li>
                                            <a href="#" className="flq-tag">#Comedy</a>
                                        </li>
                                        <li>
                                            <a href="#" className="flq-tag">#Drama</a>
                                        </li>
                                        <li>
                                            <a href="#" className="flq-tag">#Trailers</a>
                                        </li>
                                        <li>
                                            <a href="#" className="flq-tag">#Movie Trailers</a>
                                        </li>
                                        <li>
                                            <a href="#" className="flq-tag">#Horror</a>
                                        </li>
                                        <li>
                                            <a href="#" className="flq-tag">#Thriller</a>
                                        </li>
                                        <li>
                                            <a href="#" className="flq-tag">#TV Shows</a>
                                        </li>
                                        <li>
                                            <a href="#" className="flq-tag">#Action</a>
                                        </li>
                                        <li>
                                            <a href="#" className="flq-tag">#Music</a>
                                        </li>
                                        <li>
                                            <a href="#" className="flq-tag">#Rider</a>
                                        </li>
                                        <li>
                                            <a href="#" className="flq-tag">#Travel</a>
                                        </li>
                                        <li>
                                            <a href="#" className="flq-tag">#Car</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="flq-widget flq-widget-blog">
                                <h4 className="flq-widget-title h5 flq-color-opacity"><span>Recent Posts</span></h4>
                                <ul>
                                    <li>
                                        <div className="card flq-card-blog">
                                            <div className="card-img-wrap">
                                                <a href="single-post.html">
                                                    <span className="flq-image flq-responsive">
                                                        <img src="assets/images/blog/post-1-740x420.jpg" alt="" />
                                                    </span>
                                                    <span className="card-badge badge badge-dark badge-glass">November 20, 2021</span>
                                                </a>
                                            </div>
                                            <div className="card-body">
                                                <h5 className="card-title h5"><a href="single-post.html">Girlfriend. How and why</a></h5>
                                                <p>Earth first saw earth which seas void i thing said bearing behold and can&#39;t unto. Blessed...</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="card flq-card-blog">
                                            <div className="card-img-wrap">
                                                <a href="single-post.html">
                                                    <span className="flq-image flq-responsive">
                                                        <img src="assets/images/blog/post-2-740x420.jpg" alt="" />
                                                    </span>
                                                    <span className="card-badge badge badge-dark badge-glass">November 18, 2021</span>
                                                </a>
                                            </div>
                                            <div className="card-body">
                                                <h5 className="card-title h5"><a href="single-post.html">Years is air whose lesser. You&#39;ll thing waters unto brought.</a></h5>
                                                <p>From said, our they&#39;re darkness void meat from, bearing. Void, beginning fruitful he were...</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="card flq-card-blog">
                                            <div className="card-img-wrap">
                                                <a href="single-post.html">
                                                    <span className="flq-image flq-responsive">
                                                        <img src="assets/images/blog/post-3-740x420.jpg" alt="" />
                                                    </span>
                                                    <span className="card-badge badge badge-dark badge-glass">November 15, 2021</span>
                                                </a>
                                            </div>
                                            <div className="card-body">
                                                <h5 className="card-title h5"><a href="single-post.html">Is under void created his hath. One moving Appear hath.</a></h5>
                                                <p>Spirit herb subdue second form lights green isn&#39;t a sixth had creepeth is night spirit...</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default connect(null, { logout })(MobileUserDropDown);