import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "../../components/profile/Sidebar";
import { useIsAuthenticated, useUser } from "../../reducers/accounts/accountsReducer";

const Profile = ({}) => {
    const isAuthenticated = useIsAuthenticated();
    let navigate = useNavigate();
    useEffect(() => {
        if (!isAuthenticated){
            navigate("/", {replace: true});
        }
    }, [isAuthenticated]);
    const user = useUser();
    return (
        <>
            <div className="container pt-5 pb-7">
                <div className="row gy-6 gx-6">
                    <div className="col-12 col-xl order-5">
                        <div className="flq-account-content">
                            <span className="flq-account-avatar mb-4">
                                <span className="flq-image flq-responsive flq-responsive-1x1 flq-rounded">
                                    <img src={user?.thumbnail?.url} alt="" height="160" width="160" style={{maxWidth: "100%"}} />
                                </span>
                            </span>
                            <table className="table">
                                <tbody>
                                    <tr>
                                        <td className="flq-color-meta">First Name</td>
                                        <td>{user?.first_name}</td>
                                    </tr>
                                    <tr>
                                        <td className="flq-color-meta">Last Name</td>
                                        <td>{user?.last_name}</td>
                                    </tr>
                                    <tr>
                                        <td className="flq-color-meta">Email</td>
                                        <td>{user?.email}</td>
                                    </tr>
                                    <tr>
                                        <td className="flq-color-meta">Phone</td>
                                        <td>{user?.profile?.primary_phone?.phone}</td>
                                    </tr>
                                    <tr>
                                        <td className="flq-color-meta">Gender</td>
                                        <td>{user?.profile?.gender_display}</td>
                                    </tr>
                                    {/* <tr>
                                        <td className="flq-color-meta">Date of Birth</td>
                                        <td>
                                            <div className="row">
                                                <div className="col-auto">November 29, 1990</div>
                                                <div className="col-auto flq-color-meta">{new Date().getFullYear() - 1990} years</div>
                                            </div>
                                        </td>
                                    </tr> */}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <Sidebar />
                </div>
            </div>
        </>
    )
}

export default connect(null, {})(Profile);