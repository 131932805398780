import React from "react";
import { Route, Routes } from 'react-router-dom';
// import ProductDetail from "../pages/products/ProductDetail";
// import ProductList from "../pages/products/ProductList";
import AlbumList from "../pages/AlbumList";
import AlbumDetail from "../pages/AlbumDetail";

const AlbumRoutes = () => {
    return (
        <Routes>
            <Route path="/albums/" element={<AlbumList />} />
            <Route path="/albums/:albumSlug/" element={<AlbumDetail/>} />
        </Routes>
    )
}

export default AlbumRoutes;