import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Fancybox from "../FancyBox";
import { Offcanvas } from "bootstrap";
import { useIsAuthenticated, useUser } from "../../reducers/accounts/accountsReducer";
import { logout } from "../../actions/accounts/accounts";

const Navbar = ({ logout }) => {
    const isAuthenticated = useIsAuthenticated();
    const user = useUser();
    const show = () => {
        let elem = Offcanvas.getOrCreateInstance(document.getElementById("flq_offcanvas"));
        elem.show()
    }
    const loc = useLocation();
    const [scrollTop, setScrollTop] = useState(0);
    const prevYOffset = useRef(0);
    useEffect(() => {
        const handleScroll = () => {
            setScrollTop(window.pageYOffset);
        }
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);
    useEffect(() => {
        let navbar = document.querySelector('.flq-navbar');
        if (scrollTop > 62){
            navbar.classList.add('flq-navbar-top-scroll');
        }
        else{
            navbar.classList.remove('flq-navbar-top-scroll', 'flq-navbar-top-hide');
        }
        if (scrollTop > 350){
            if (scrollTop > prevYOffset.current){
                navbar.classList.add('flq-navbar-top-hide');
            }
            else{
                navbar.classList.remove('flq-navbar-top-hide');
            }
        }
        prevYOffset.current = scrollTop;
    }, [scrollTop]);
    const logoutUser = () => {
        if (isAuthenticated)
            logout()
    }
    return (
        <>
            <nav className={`flq-navbar flq-navbar-top ${loc.pathname === "/" ? "flq-navbar-top-cloud flq-navbar-top-fixed" : ""}`}>
                <div className="flq-navbar-container container-fluid">
                    {/* <button onClick={show} className="btn btn-link me-4" data-bs-toggle="offcanvas" data-bs-target="#flq_offcanvas">
                        <span></span>
                        <span></span>
                        <span></span>
                    </button> */}
                    <Link to="/" className="flq-navbar-brand me-auto me-auto me-lg-4">
                        {/* <img src={require("../../assets/images/logo.svg").default} className="flq-logo" alt="" /> */}
                        <img src={require("../../assets/images/logo.png")} className="flq-logo" alt="" />
                    </Link>
                    <ul className="nav nav-parent flex-grow-1 flq-navbar-nav justify-content-center d-none d-lg-flex">
                        <li className={`nav-item ${loc.pathname.split("/")[1] === "" ? 'active' : ''}`}>
                            <Link to="/" className="nav-link">
                                <span className="nav-link-name">Home</span>
                            </Link>
                        </li>
                        <li className={`nav-item ${loc.pathname.split("/")[1] === "music" ? 'active' : ''}`}>
                            <Link to="/music/" className="nav-link">
                                <span className="nav-link-name">Music</span>
                            </Link>
                        </li>
                        <li className={`nav-item ${loc.pathname.split("/")[1] === "albums" ? 'active' : ''}`}>
                            <Link to="/albums/" className="nav-link">
                                <span className="nav-link-name">Albums</span>
                            </Link>
                        </li>
                        {/* <li className={`nav-item ${loc.pathname.split("/")[1] === "read" ? 'active' : ''}`}>
                            <Link to="/read/" className="nav-link">
                                <span className="nav-link-name">Read</span>
                            </Link>
                        </li> */}
                        {/* <li className="nav-item flq-dropdown flq-dropdown-nav">
                            <a href="movies.html" className="nav-link" role="button">
                                <span className="nav-link-name">Music</span>
                            </a>
                            <div className="dropdown-menu gy-2">
                                <ul className="nav">
                                    <li className="nav-item">
                                        <a href="movies.html" className="nav-link">
                                            <span className="nav-link-name">Movies</span>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="single-movie.html" className="nav-link">
                                            <span className="nav-link-name">Single Movie</span>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="single-actor.html" className="nav-link">
                                            <span className="nav-link-name">Single Actor</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </li> */}
                        {/* <li className="nav-item">
                            <a href="tv-shows.html" className="nav-link">
                                <span className="nav-link-name">TV Shows</span>
                            </a>
                        </li> */}
                        {/* <li className="nav-item flq-dropdown flq-dropdown-nav">
                            <a href="blog.html" className="nav-link" role="button">
                                <span className="nav-link-name">Blog</span>
                            </a>
                            <div className="dropdown-menu gy-2">
                                <ul className="nav">
                                    <li className="nav-item">
                                        <a href="blog.html" className="nav-link">
                                            <span className="nav-link-name">Blog</span>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="single-post.html" className="nav-link">
                                            <span className="nav-link-name">Single Post</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </li> */}
                        {/* <li className="nav-item flq-dropdown flq-dropdown-nav">
                            <a href="about.html" className="nav-link" role="button">
                                <span className="nav-link-name">Pages</span>
                            </a>
                            <div className="dropdown-menu gy-2">
                                <ul className="nav">
                                    <li className="nav-item">
                                        <a href="about.html" className="nav-link">
                                            <span className="nav-link-name">About</span>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="contact.html" className="nav-link">
                                            <span className="nav-link-name">Contact Us</span>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="faq.html" className="nav-link">
                                            <span className="nav-link-name">FAQ</span>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="coming-soon.html" className="nav-link">
                                            <span className="nav-link-name">Coming Soon</span>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="404.html" className="nav-link">
                                            <span className="nav-link-name">404</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </li> */}
                    </ul>
                    {/* <Fancybox options={
                        {
                            infinite: false, 
                            mainClass: "flq-fancybox-open flq-fancybox-search",
                            keyboard: false,
                            toolbar: false,
                            closeExisting: true,
                            touch: false,
                            autoFocus: false,
                            animated: true,
                            dragToClose: false
                        }
                    }>
                        <button className="btn btn-link btn-icon-md ms-5 d-none d-sm-flex" data-fancybox="flq_popup_search" data-src="#flq_popup_search" data-base-className="flq-fancybox-search" data-animation-duration="1000" data-keyboard="false" data-auto-focus="true" data-touch="false" data-close-existing="true" data-small-btn="false" data-toolbar="false">
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.0833 17.4167C14.1334 17.4167 17.4167 14.1334 17.4167 10.0833C17.4167 6.03325 14.1334 2.75 10.0833 2.75C6.03325 2.75 2.75 6.03325 2.75 10.0833C2.75 14.1334 6.03325 17.4167 10.0833 17.4167Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M19.25 19.25L15.2625 15.2625" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </button>
                    </Fancybox> */}
                    
                    { !isAuthenticated ? 
                    <div className="d-flex align-items-center ms-4 d-none d-sm-flex">
                        <Fancybox options={
                            {
                                infinite: false, 
                                mainClass: "flq-fancybox-open flq-fancybox-signin",
                                keyboard: false,
                                toolbar: false,
                                closeExisting: true,
                                touch: false,
                                autoFocus: false,
                                animated: true,
                                dragToClose: false
                            }
                        }>
                            <button className="btn btn-xs btn-outline btn-white d-none d-md-flex" data-fancybox="signinmd" data-src="#flq_popup_signin" data-base-className="flq-fancybox-signin" data-animation-duration="1000" data-keyboard="false" data-auto-focus="false" data-touch="false" data-close-existing="true" data-small-btn="false" data-toolbar="false"> Join Now </button>
                            <button className="btn btn-link btn-icon-md d-md-none" data-fancybox="signin" data-src="#flq_popup_signin" data-base-className="flq-fancybox-signin" data-animation-duration="1000" data-keyboard="false" data-auto-focus="false" data-touch="false" data-close-existing="true" data-small-btn="false" data-toolbar="false">
                                <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10 17L15 12M15 12L10 7M15 12H3M15 3H19C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H15" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </button>
                        </Fancybox>
                    </div> : 
                    <div className="flq-dropdown flq-dropdown-nav ms-4 d-none d-sm-flex">
                        <button className="btn btn-link align-self-center">
                            <span className="flq-image flq-navbar-top-user">
                                <img src={user?.thumbnail?.url} height="160" width="160" style={{maxWidth: "100%"}}  alt="" />
                            </span>
                        </button>
                        <div className="dropdown-menu gy-2 flq-dropdown-drop-left">
                            <ul className="nav">
                                <li className="nav-item">
                                    <Link to="/profile/" className="nav-link">
                                        <span className="nav-link-name">Profile</span>
                                    </Link>
                                </li>
                                {/* <li className="nav-item">
                                    <Link to="/my-favorites/" className="nav-link">
                                        <span className="nav-link-name">My Favorites</span>
                                    </Link>
                                </li> */}
                                {/* <li className="nav-item">
                                    <a href="account-reviews.html" className="nav-link">
                                        <span className="nav-link-name">Reviews & Comments</span>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="account-edit.html" className="nav-link">
                                        <span className="nav-link-name">Account Edit</span>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="account-notifications.html" className="nav-link">
                                        <span className="nav-link-name">Notifications</span>
                                    </a>
                                </li> */}
                                <li className="nav-item">
                                    <Link to="" onClick={logoutUser} className="nav-link">
                                        <span className="nav-link-name">Logout</span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>}
                    <Fancybox options={
                        {
                            infinite: false, 
                            mainClass: "flq-fancybox-open flq-fancybox-navbar",
                            keyboard: false,
                            toolbar: false,
                            closeExisting: true,
                            touch: false,
                            autoFocus: false,
                            animated: true,
                            dragToClose: false
                        }
                    }>
                        <button className="flq-navbar-top-toggle btn btn-link ms-4 d-flex d-lg-none" 
                            data-fancybox="flq_navbar_mobile"
                            data-src="#flq_navbar_mobile" 
                            // data-base-className="flq-fancybox-navbar"
                            data-animation-duration="1000" 
                            data-keyboard="false" 
                            data-auto-focus="false" 
                            data-touch="false" 
                            data-close-existing="true" 
                            data-small-btn="false" 
                            data-toolbar="false">
                                <span></span>
                                <span></span>
                                <span></span>
                        </button>
                    </Fancybox>
                </div>
            </nav>
        </>
    )
}

export default connect(null, { logout })(Navbar);