import { Fancybox } from "@fancyapps/ui/dist/fancybox.esm";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { signup } from "../../actions/accounts/accounts";
import { useIsAuthenticated, useUser } from "../../reducers/accounts/accountsReducer";

const SignupPopup =({ signup }) => {
    const isAuthenticated = useIsAuthenticated();
    let navigate = useNavigate();
    const [Form, setForm] = useState({
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        password: null,
        confirm_password: null
    })
    const updateForm = (e) => {
        setForm({...Form, [e.target.name]: e.target.value});
    }
    const validate = () => {
        if (Form.password !== Form.confirm_password){
            window.alert("Passwords does not match");
            return false;
        }
        if (!Form.first_name || Form.first_name.length <= 0 || !Form.last_name || Form.last_name.length <= 0 || !Form.email || Form.email.length <= 0 || !Form.phone || Form.phone.length <= 0){
            window.alert("Required information missing");
            return false;
        }
        return true;
    }
    const submit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        let valid = validate();
        if (!valid){
            return;
        }
        signup(Form.email, Form.password, Form.first_name, Form.last_name, Form.phone);
    }
    return (
        <div className="flq-fancybox-content-signup fancybox-content" id="signup">
            <div className="flq-signup">
                <div className="flq-fancybox-head">
                    <div className="container-fluid">
                        <Link to="/" className="flq-fancybox-brand me-auto">
                            <img src={require("../../assets/images/logo.png")} className="flq-logo" alt="" />
                        </Link>
                        <button className="flq-fancybox-close btn btn-link ms-4" data-fancybox-close>
                            <span data-fancybox-close></span>
                        </button>
                    </div>
                </div>
                <div className="flq-fancybox-body pb-6">
                    <form className="flq-signup-content">
                        <h4 className="mb-4 pb-1 text-center">Sign Up</h4>
                        <div className="row gy-4">
                            <div className="col-12">
                                <input className="form-control flq-form-user flq-form-translucent"
                                    onChange={updateForm} 
                                    name="first_name"
                                    value={Form.first_name}
                                    placeholder="First Name" />
                            </div>
                            <div className="col-12">
                                <input className="form-control flq-form-user flq-form-translucent"
                                    onChange={updateForm}
                                    name="last_name"
                                    value={Form.last_name} placeholder="Last Name" />
                            </div>
                            <div className="col-12">
                                <input className="form-control flq-form-phone flq-form-translucent"
                                    onChange={updateForm} 
                                    name="phone"
                                    value={Form.phone} placeholder="Phone" />
                            </div>
                            <div className="col-12">
                                <input className="form-control flq-form-mail flq-form-translucent"
                                    onChange={updateForm}
                                    name="email"
                                    value={Form.email} type="email" placeholder="Email" />
                            </div>
                            <div className="col-12">
                                <input className="form-control flq-form-lock flq-form-translucent"
                                    onChange={updateForm}
                                    name="password"
                                    value={Form.password} type="password" placeholder="Password" />
                            </div>
                            <div className="col-12">
                                <input className="form-control flq-form-lock flq-form-translucent"
                                    onChange={updateForm}
                                    name="confirm_password"
                                    value={Form.confirm_password} type="password" placeholder="Confirm Password" />
                            </div>
                            {/* <div className="col-12">
                                <div className="form-check flq-form-translucent">
                                    <input className="form-check-input" type="checkbox" id="signupTerms" /><label className="form-check-label" for="signupTerms">I have read and agree to the terms and conditions.</label>
                                </div>
                            </div> */}
                            <div className="col-12">
                                <button type="submit" className="btn btn-block" onClick={submit}>Sign Up</button>
                            </div>
                            {/* <div className="col-12 text-center mt-1">or</div>
                            <div className="col-12 mt-1">
                                <div className="row justify-content-center gy-1">
                                    <div className="col-auto">
                                        <a href="#" className="btn btn-link">Facebook</a>
                                    </div>
                                    <div className="col-auto">
                                        <a href="#" className="btn btn-link">Twitter</a>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default connect(null, { signup })(SignupPopup);