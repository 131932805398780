import React from "react";
import { connect } from "react-redux";

const OnlineShows = () => {
    return (
        <>
            <div className="container my-7" data-sr="online-streaming" data-sr-interval="100" data-sr-duration="1000" data-sr-distance="10">
                <div className="text-center mb-5">
                    <span className="flq-subtitle badge" data-sr-item="online-streaming">Online Streaming</span>
                    <h2 data-sr-item="online-streaming">Watch Shows Online</h2>
                </div>
                <div className="row gy-5 justify-content-center">
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2" data-sr-item="online-streaming">
                        <div className="card flq-card-blog">
                            <div className="card-img-wrap">
                                <a href="single-movie.html">
                                    <span className="flq-image flq-rounded-lg
      flq-responsive flq-responsive-sm-3x4">
                                        <img src={require("../../assets/images/tv-shows/show-1-390x440.jpg")} alt="" />
                                    </span>
                                    <span className="card-badge badge badge-dark badge-glass flq-color-white badge-sm">7.2</span>
                                </a>
                            </div>
                            <div className="card-body">
                                <h5 className="card-title h6"><a href="single-movie.html">Guru Puja</a></h5>
                                <div className="flq-meta flq-meta-sm">
                                    <ul>
                                        <li>
                                            <a href="#" className="card-year">2021</a>
                                        </li>
                                        <li>
                                            <a href="#" className="card-category">Devotional</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2" data-sr-item="online-streaming">
                        <div className="card flq-card-blog">
                            <div className="card-img-wrap">
                                <a href="single-movie.html">
                                    <span className="flq-image flq-rounded-lg
      flq-responsive flq-responsive-sm-3x4">
                                        <img src={require("../../assets/images/tv-shows/show-2-390x440.jpg")} alt="" />
                                    </span>
                                    <span className="card-badge badge badge-dark badge-glass flq-color-white badge-sm">6.9</span>
                                </a>
                            </div>
                            <div className="card-body">
                                <h5 className="card-title h6"><a href="single-movie.html">Guru Puja</a></h5>
                                <div className="flq-meta flq-meta-sm">
                                    <ul>
                                        <li>
                                            <a href="#" className="card-year">2019</a>
                                        </li>
                                        <li>
                                            <a href="#" className="card-category">Devotional</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2" data-sr-item="online-streaming">
                        <div className="card flq-card-blog">
                            <div className="card-img-wrap">
                                <a href="single-movie.html">
                                    <span className="flq-image flq-rounded-lg
      flq-responsive flq-responsive-sm-3x4">
                                        <img src={require("../../assets/images/tv-shows/show-3-390x440.jpg")} alt="" />
                                    </span>
                                    <span className="card-badge badge badge-dark badge-glass flq-color-white badge-sm">6.2</span>
                                </a>
                            </div>
                            <div className="card-body">
                                <h5 className="card-title h6"><a href="single-movie.html">Guru Puja</a></h5>
                                <div className="flq-meta flq-meta-sm">
                                    <ul>
                                        <li>
                                            <a href="#" className="card-year">2016</a>
                                        </li>
                                        <li>
                                            <a href="#" className="card-category">Devotional</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2" data-sr-item="online-streaming">
                        <div className="card flq-card-blog">
                            <div className="card-img-wrap">
                                <a href="single-movie.html">
                                    <span className="flq-image flq-rounded-lg
      flq-responsive flq-responsive-sm-3x4">
                                        <img src={require("../../assets/images/tv-shows/show-4-390x440.jpg")} alt="" />
                                    </span>
                                    <span className="card-badge badge badge-dark badge-glass flq-color-white badge-sm">7.6</span>
                                </a>
                            </div>
                            <div className="card-body">
                                <h5 className="card-title h6"><a href="single-movie.html">Guru Puja</a></h5>
                                <div className="flq-meta flq-meta-sm">
                                    <ul>
                                        <li>
                                            <a href="#" className="card-year">2018</a>
                                        </li>
                                        <li>
                                            <a href="#" className="card-category">Devotional</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2" data-sr-item="online-streaming">
                        <div className="card flq-card-blog">
                            <div className="card-img-wrap">
                                <a href="single-movie.html">
                                    <span className="flq-image flq-rounded-lg
      flq-responsive flq-responsive-sm-3x4">
                                        <img src={require("../../assets/images/tv-shows/show-5-390x440.jpg")} alt="" />
                                    </span>
                                    <span className="card-badge badge badge-dark badge-glass flq-color-white badge-sm">7.8</span>
                                </a>
                            </div>
                            <div className="card-body">
                                <h5 className="card-title h6"><a href="single-movie.html">Guru Puja</a></h5>
                                <div className="flq-meta flq-meta-sm">
                                    <ul>
                                        <li>
                                            <a href="#" className="card-year">2021</a>
                                        </li>
                                        <li>
                                            <a href="#" className="card-category">Devotional</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2" data-sr-item="online-streaming">
                        <div className="card flq-card-blog">
                            <div className="card-img-wrap">
                                <a href="single-movie.html">
                                    <span className="flq-image flq-rounded-lg
      flq-responsive flq-responsive-sm-3x4">
                                        <img src={require("../../assets/images/tv-shows/show-6-390x440.jpg")} alt="" />
                                    </span>
                                    <span className="card-badge badge badge-dark badge-glass flq-color-white badge-sm">9.0</span>
                                </a>
                            </div>
                            <div className="card-body">
                                <h5 className="card-title h6"><a href="single-movie.html">Guru Puja</a></h5>
                                <div className="flq-meta flq-meta-sm">
                                    <ul>
                                        <li>
                                            <a href="#" className="card-year">2014</a>
                                        </li>
                                        <li>
                                            <a href="#" className="card-category">Devotional</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2" data-sr-item="online-streaming">
                        <div className="card flq-card-blog">
                            <div className="card-img-wrap">
                                <a href="single-movie.html">
                                    <span className="flq-image flq-rounded-lg
      flq-responsive flq-responsive-sm-3x4">
                                        <img src={require("../../assets/images/tv-shows/show-7-390x440.jpg")} alt="" />
                                    </span>
                                    <span className="card-badge badge badge-dark badge-glass flq-color-white badge-sm">6.0</span>
                                </a>
                            </div>
                            <div className="card-body">
                                <h5 className="card-title h6"><a href="single-movie.html">Guru Puja</a></h5>
                                <div className="flq-meta flq-meta-sm">
                                    <ul>
                                        <li>
                                            <a href="#" className="card-year">2000</a>
                                        </li>
                                        <li>
                                            <a href="#" className="card-category">Devotional</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2" data-sr-item="online-streaming">
                        <div className="card flq-card-blog">
                            <div className="card-img-wrap">
                                <a href="single-movie.html">
                                    <span className="flq-image flq-rounded-lg
      flq-responsive flq-responsive-sm-3x4">
                                        <img src={require("../../assets/images/tv-shows/show-8-390x440.jpg")} alt="" />
                                    </span>
                                    <span className="card-badge badge badge-dark badge-glass flq-color-white badge-sm">6.2</span>
                                </a>
                            </div>
                            <div className="card-body">
                                <h5 className="card-title h6"><a href="single-movie.html">Guru Puja</a></h5>
                                <div className="flq-meta flq-meta-sm">
                                    <ul>
                                        <li>
                                            <a href="#" className="card-year">2015</a>
                                        </li>
                                        <li>
                                            <a href="#" className="card-category">Devotional</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2" data-sr-item="online-streaming">
                        <div className="card flq-card-blog">
                            <div className="card-img-wrap">
                                <a href="single-movie.html">
                                    <span className="flq-image flq-rounded-lg
      flq-responsive flq-responsive-sm-3x4">
                                        <img src={require("../../assets/images/tv-shows/show-9-390x440.jpg")} alt="" />
                                    </span>
                                    <span className="card-badge badge badge-dark badge-glass flq-color-white badge-sm">7.1</span>
                                </a>
                            </div>
                            <div className="card-body">
                                <h5 className="card-title h6"><a href="single-movie.html">Guru Puja</a></h5>
                                <div className="flq-meta flq-meta-sm">
                                    <ul>
                                        <li>
                                            <a href="#" className="card-year">2019</a>
                                        </li>
                                        <li>
                                            <a href="#" className="card-category">Devotional</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2" data-sr-item="online-streaming">
                        <div className="card flq-card-blog">
                            <div className="card-img-wrap">
                                <a href="single-movie.html">
                                    <span className="flq-image flq-rounded-lg
      flq-responsive flq-responsive-sm-3x4">
                                        <img src={require("../../assets/images/tv-shows/show-10-390x440.jpg")} alt="" />
                                    </span>
                                    <span className="card-badge badge badge-dark badge-glass flq-color-white badge-sm">6.7</span>
                                </a>
                            </div>
                            <div className="card-body">
                                <h5 className="card-title h6"><a href="single-movie.html">Guru Puja</a></h5>
                                <div className="flq-meta flq-meta-sm">
                                    <ul>
                                        <li>
                                            <a href="#" className="card-year">2017</a>
                                        </li>
                                        <li>
                                            <a href="#" className="card-category">Devotional</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2" data-sr-item="online-streaming">
                        <div className="card flq-card-blog">
                            <div className="card-img-wrap">
                                <a href="single-movie.html">
                                    <span className="flq-image flq-rounded-lg
      flq-responsive flq-responsive-sm-3x4">
                                        <img src={require("../../assets/images/tv-shows/show-11-390x440.jpg")} alt="" />
                                    </span>
                                    <span className="card-badge badge badge-dark badge-glass flq-color-white badge-sm">6.3</span>
                                </a>
                            </div>
                            <div className="card-body">
                                <h5 className="card-title h6"><a href="single-movie.html">Guru Puja</a></h5>
                                <div className="flq-meta flq-meta-sm">
                                    <ul>
                                        <li>
                                            <a href="#" className="card-year">2003</a>
                                        </li>
                                        <li>
                                            <a href="#" className="card-category">Devotional</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2" data-sr-item="online-streaming">
                        <div className="card flq-card-blog">
                            <div className="card-img-wrap">
                                <a href="single-movie.html">
                                    <span className="flq-image flq-rounded-lg
      flq-responsive flq-responsive-sm-3x4">
                                        <img src={require("../../assets/images/tv-shows/show-12-390x440.jpg")} alt="" />
                                    </span>
                                    <span className="card-badge badge badge-dark badge-glass flq-color-white badge-sm">8.6</span>
                                </a>
                            </div>
                            <div className="card-body">
                                <h5 className="card-title h6"><a href="single-movie.html">Guru Puja</a></h5>
                                <div className="flq-meta flq-meta-sm">
                                    <ul>
                                        <li>
                                            <a href="#" className="card-year">2014</a>
                                        </li>
                                        <li>
                                            <a href="#" className="card-category">Devotional</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="text-center mt-5" data-sr-item="online-streaming">
                    <a href="tv-shows.html" className="btn">Browse All</a>
                </div>
            </div>
        </>
    )
}

export default connect(null, {})(OnlineShows);