import React from "react";
import { Route, Routes } from 'react-router-dom';
import MyFavorites from "../pages/profile/MyFavorites";
import Profile from "../pages/profile/Profile";

const ProfileRoutes = () => {
    return (
        <Routes>
            <Route path="/profile/" element={<Profile />} />
            <Route path="/my-favorites/" element={<MyFavorites/>} />
        </Routes>
    )
}

export default ProfileRoutes;