import React from "react";
import { connect } from "react-redux";

const BestSerials = ({}) => {
    return (
        <>
            <div className="container flq-swiper-wrapper my-7" data-sr="best-serials" data-sr-interval="80" data-sr-duration="1000" data-sr-distance="10">
                <div className="row align-items-center justify-content-between gx-5 gy-4 mb-5">
                    <div className="col-auto" data-sr-item="best-serials">
                        <h3>Trending</h3>
                    </div>
                    <div className="col d-none d-sm-block" data-sr-item="best-serials">
                        <hr />
                    </div>
                    <div className="col-auto" data-sr-item="best-serials">
                        <div className="
          flq-swiper-button-prev
          btn btn-sm btn-white btn-brand-hover btn-active btn-square btn-icon-sm
          me-1
        ">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15 18L9 12L15 6" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>
                        <div className="
          flq-swiper-button-next
          btn btn-sm btn-white btn-brand-hover btn-active btn-square btn-icon-sm
        ">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 6L15 12L9 18" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>
                    </div>
                </div>
                <div className="swiper flq-swiper-effect-touch" 
                    data-sr-item="best-serials" 
                    data-buttons={true} 
                    data-gap={30} data-loop={true} data-speed={800} data-touch-ratio={0.8} data-breakpoints={{320:1,656:2,1072:3,1280:4}}>
                    <div className="swiper-container">
                        <div className="swiper-wrapper">
                            <div className="swiper-slide">
                                <div className="card flq-card-blog">
                                    <div className="card-img-wrap">
                                        <a href="single-movie.html">
                                            <span className="flq-image flq-responsive
    flq-responsive-sm-3x4">
                                                <img src="assets/images/tv-shows/show-3-1290x1290.jpg" alt="" />
                                            </span>
                                            <span className="card-badge badge badge-dark badge-glass flq-color-white">6.2</span>
                                        </a>
                                    </div>
                                    <div className="card-body">
                                        <h5 className="card-title"><a href="single-movie.html">Guru Pooja</a></h5>
                                        <div className="flq-meta flq-meta-sm">
                                            <ul>
                                                <li>
                                                    <a href="#" className="card-year">2016</a>
                                                </li>
                                                <li>
                                                    <a href="#" className="card-category">Devotional</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="card flq-card-blog">
                                    <div className="card-img-wrap">
                                        <a href="single-movie.html">
                                            <span className="flq-image flq-responsive
    flq-responsive-sm-3x4">
                                                <img src="assets/images/tv-shows/show-5-1290x1290.jpg" alt="" />
                                            </span>
                                            <span className="card-badge badge badge-dark badge-glass flq-color-white">7.8</span>
                                        </a>
                                    </div>
                                    <div className="card-body">
                                        <h5 className="card-title"><a href="single-movie.html">Guru Pooja</a></h5>
                                        <div className="flq-meta flq-meta-sm">
                                            <ul>
                                                <li>
                                                    <a href="#" className="card-year">2021</a>
                                                </li>
                                                <li>
                                                    <a href="#" className="card-category">Devotional</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="card flq-card-blog">
                                    <div className="card-img-wrap">
                                        <a href="single-movie.html">
                                            <span className="flq-image flq-responsive
    flq-responsive-sm-3x4">
                                                <img src="assets/images/tv-shows/show-7-1290x1290.jpg" alt="" />
                                            </span>
                                            <span className="card-badge badge badge-dark badge-glass flq-color-white">6.0</span>
                                        </a>
                                    </div>
                                    <div className="card-body">
                                        <h5 className="card-title"><a href="single-movie.html">Guru Pooja</a></h5>
                                        <div className="flq-meta flq-meta-sm">
                                            <ul>
                                                <li>
                                                    <a href="#" className="card-year">2000</a>
                                                </li>
                                                <li>
                                                    <a href="#" className="card-category">Devotional</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="card flq-card-blog">
                                    <div className="card-img-wrap">
                                        <a href="single-movie.html">
                                            <span className="flq-image flq-responsive
    flq-responsive-sm-3x4">
                                                <img src="assets/images/tv-shows/show-9-1290x1290.jpg" alt="" />
                                            </span>
                                            <span className="card-badge badge badge-dark badge-glass flq-color-white">7.1</span>
                                        </a>
                                    </div>
                                    <div className="card-body">
                                        <h5 className="card-title"><a href="single-movie.html">Guru Pooja</a></h5>
                                        <div className="flq-meta flq-meta-sm">
                                            <ul>
                                                <li>
                                                    <a href="#" className="card-year">2019</a>
                                                </li>
                                                <li>
                                                    <a href="#" className="card-category">Devotional</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="card flq-card-blog">
                                    <div className="card-img-wrap">
                                        <a href="single-movie.html">
                                            <span className="flq-image flq-responsive
    flq-responsive-sm-3x4">
                                                <img src="assets/images/tv-shows/show-11-1290x1290.jpg" alt="" />
                                            </span>
                                            <span className="card-badge badge badge-dark badge-glass flq-color-white">6.3</span>
                                        </a>
                                    </div>
                                    <div className="card-body">
                                        <h5 className="card-title"><a href="single-movie.html">Guru Pooja</a></h5>
                                        <div className="flq-meta flq-meta-sm">
                                            <ul>
                                                <li>
                                                    <a href="#" className="card-year">2003</a>
                                                </li>
                                                <li>
                                                    <a href="#" className="card-category">Devotional</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default connect(null, {})(BestSerials);