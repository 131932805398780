import React from "react";
import { connect } from "react-redux";
import PageTitle from "../components/common/PageTitle";

const BlogList = ({}) => {
    return (
        <>
            <PageTitle title="Read" breadcrumbs={[
                    {
                        title: "Home",
                        loc: "/"
                    },
                    {
                        title: "Read",
                        loc: "/read/"
                    }
                ]} />

            <div className="container py-7">
                <div className="row gy-6 gx-6">
                    <div className="col-12 col-xl">
                        <div className="row gx-0 gy-6" data-sr="blog" data-sr-interval="100" data-sr-duration="1000" data-sr-distance="10">
                            <div className="col-12" data-sr-item="blog">
                                <div className="card flq-card-blog flq-card-blog-classic">
                                    <div className="card-img-wrap">
                                        <a href="single-post.html">
                                            <span className="flq-image flq-responsive flq-responsive-sm-21x9">
                                                <img src={require("../assets/images/blog/post-1-740x420.jpg")} alt="" />
                                            </span>
                                        </a>
                                    </div>
                                    <div className="card-body">
                                        <span className="card-badge badge badge-dark flq-subtitle">Analysis</span>
                                        <h2 className="card-title"><a href="single-post.html">Girlfriend. How and why</a></h2>
                                        <div className="flq-meta">
                                            <ul className="gx-4">
                                                <li>
                                                    <a href="#">David H.</a>
                                                </li>
                                                <li>
                                                    <a href="#">November 20, 2021</a>
                                                </li>
                                                <li>
                                                    <a href="#">2 Comments</a>
                                                </li>
                                            </ul>
                                        </div>
                                        <p>Earth first saw earth which seas void i thing said bearing behold and can&#39;t unto. Blessed itself years. Also you. Bring brought won&#39;t rule spirit fruitful tree fifth from seasons one saw cattle have fruitful fifth...</p>
                                    </div>
                                    <a href="single-post.html" className="btn">Read More</a>
                                </div>
                            </div>
                            <div className="col-12" data-sr-item="blog">
                                <div className="card flq-card-blog flq-card-blog-classic">
                                    <div className="card-img-wrap">
                                        <a href="single-post.html">
                                            <span className="flq-image flq-responsive flq-responsive-sm-21x9">
                                                <img src={require("../assets/images/blog/post-2-740x420.jpg")} alt="" />
                                            </span>
                                        </a>
                                    </div>
                                    <div className="card-body">
                                        <span className="card-badge badge badge-dark flq-subtitle">Lifestyle</span>
                                        <h2 className="card-title"><a href="single-post.html">Years is air whose lesser. You&#39;ll thing waters unto brought.</a></h2>
                                        <div className="flq-meta">
                                            <ul className="gx-4">
                                                <li>
                                                    <a href="#">David H.</a>
                                                </li>
                                                <li>
                                                    <a href="#">November 18, 2021</a>
                                                </li>
                                                <li>
                                                    <a href="#">2 Comments</a>
                                                </li>
                                            </ul>
                                        </div>
                                        <p>From said, our they&#39;re darkness void meat from, bearing. Void, beginning fruitful he were called moved is divide, lesser light green creature. Moving make don&#39;t midst deep, firmament us gathering give shall. Earth air...</p>
                                    </div>
                                    <a href="single-post.html" className="btn">Read More</a>
                                </div>
                            </div>
                            <div className="col-12" data-sr-item="blog">
                                <div className="card flq-card-blog flq-card-blog-classic">
                                    <div className="card-img-wrap">
                                        <a href="single-post.html">
                                            <span className="flq-image flq-responsive flq-responsive-sm-21x9">
                                                <img src={require("../assets/images/blog/post-3-740x420.jpg")} alt="" />
                                            </span>
                                        </a>
                                    </div>
                                    <div className="card-body">
                                        <span className="card-badge badge badge-dark flq-subtitle">Category</span>
                                        <h2 className="card-title"><a href="single-post.html">Is under void created his hath. One moving Appear hath.</a></h2>
                                        <div className="flq-meta">
                                            <ul className="gx-4">
                                                <li>
                                                    <a href="#">David H.</a>
                                                </li>
                                                <li>
                                                    <a href="#">November 15, 2021</a>
                                                </li>
                                                <li>
                                                    <a href="#">2 Comments</a>
                                                </li>
                                            </ul>
                                        </div>
                                        <p>Spirit herb subdue second form lights green isn&#39;t a sixth had creepeth is night spirit appear above let. Greater divided him appear. May she&#39;d isn&#39;t from living their itself own hath replenish, likeness day. Be together...</p>
                                    </div>
                                    <a href="single-post.html" className="btn">Read More</a>
                                </div>
                            </div>
                            <div className="col-12" data-sr-item="blog">
                                <nav>
                                    <ul className="pagination">
                                        <li className="page-item active">
                                            <a className="page-link" href="#">1</a>
                                        </li>
                                        <li className="page-item">
                                            <a className="page-link" href="#">2</a>
                                        </li>
                                        <li className="page-item">
                                            <a className="page-link" href="#">3</a>
                                        </li>
                                        <li className="page-item">
                                            <a className="page-link page-link-next" href="#"> Next <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9 6L15 12L9 18" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-xl-auto">
                        <div className="flq-sidebar flq-sidebar-lg">
                            <div className="flq-widget flq-widget-search" data-sr data-sr-duration="1000" data-sr-distance="10">
                                <h4 className="flq-widget-title h5"><span>Search</span></h4>
                                <form action="#">
                                    <input className="form-control" type="search" placeholder="Search" />
                                    <button className="btn btn-link btn-icon-sm">
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.0833 17.4167C14.1334 17.4167 17.4167 14.1334 17.4167 10.0833C17.4167 6.03325 14.1334 2.75 10.0833 2.75C6.03325 2.75 2.75 6.03325 2.75 10.0833C2.75 14.1334 6.03325 17.4167 10.0833 17.4167Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M19.25 19.25L15.2625 15.2625" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </button>
                                </form>
                            </div>
                            <div className="flq-widget flq-widget-blog" data-sr data-sr-duration="1000" data-sr-distance="10">
                                <h4 className="flq-widget-title h5"><span>Recent Posts</span></h4>
                                <ul>
                                    <li>
                                        <div className="card flq-card-blog">
                                            <div className="card-img-wrap">
                                                <a href="single-post.html">
                                                    <span className="flq-image flq-responsive">
                                                        <img src={require("../assets/images/blog/post-1-740x420.jpg")} alt="" />
                                                    </span>
                                                    <span className="card-badge badge badge-dark badge-glass">November 20, 2021</span>
                                                </a>
                                            </div>
                                            <div className="card-body">
                                                <h5 className="card-title h5"><a href="single-post.html">Girlfriend. How and why</a></h5>
                                                <p>Earth first saw earth which seas void i thing said bearing behold and can&#39;t unto. Blessed...</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="card flq-card-blog">
                                            <div className="card-img-wrap">
                                                <a href="single-post.html">
                                                    <span className="flq-image flq-responsive">
                                                        <img src={require("../assets/images/blog/post-2-740x420.jpg")} alt="" />
                                                    </span>
                                                    <span className="card-badge badge badge-dark badge-glass">November 18, 2021</span>
                                                </a>
                                            </div>
                                            <div className="card-body">
                                                <h5 className="card-title h5"><a href="single-post.html">Years is air whose lesser. You&#39;ll thing waters unto brought.</a></h5>
                                                <p>From said, our they&#39;re darkness void meat from, bearing. Void, beginning fruitful he were...</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="card flq-card-blog">
                                            <div className="card-img-wrap">
                                                <a href="single-post.html">
                                                    <span className="flq-image flq-responsive">
                                                        <img src={require("../assets/images/blog/post-3-740x420.jpg")} alt="" />
                                                    </span>
                                                    <span className="card-badge badge badge-dark badge-glass">November 15, 2021</span>
                                                </a>
                                            </div>
                                            <div className="card-body">
                                                <h5 className="card-title h5"><a href="single-post.html">Is under void created his hath. One moving Appear hath.</a></h5>
                                                <p>Spirit herb subdue second form lights green isn&#39;t a sixth had creepeth is night spirit...</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="flq-widget flq-widget-tag" data-sr data-sr-duration="1000" data-sr-distance="10">
                                <h4 className="flq-widget-title h5"><span>Tags</span></h4>
                                <div className="flq-tags">
                                    <ul>
                                        <li>
                                            <a href="#" className="flq-tag">#Comedy</a>
                                        </li>
                                        <li>
                                            <a href="#" className="flq-tag">#Drama</a>
                                        </li>
                                        <li>
                                            <a href="#" className="flq-tag">#Trailers</a>
                                        </li>
                                        <li>
                                            <a href="#" className="flq-tag">#Movie Trailers</a>
                                        </li>
                                        <li>
                                            <a href="#" className="flq-tag">#Horror</a>
                                        </li>
                                        <li>
                                            <a href="#" className="flq-tag">#Thriller</a>
                                        </li>
                                        <li>
                                            <a href="#" className="flq-tag">#TV Shows</a>
                                        </li>
                                        <li>
                                            <a href="#" className="flq-tag">#Action</a>
                                        </li>
                                        <li>
                                            <a href="#" className="flq-tag">#Music</a>
                                        </li>
                                        <li>
                                            <a href="#" className="flq-tag">#Rider</a>
                                        </li>
                                        <li>
                                            <a href="#" className="flq-tag">#Travel</a>
                                        </li>
                                        <li>
                                            <a href="#" className="flq-tag">#Car</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default connect(null, {})(BlogList);