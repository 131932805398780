import { configureStore } from '@reduxjs/toolkit'

import { pagemetaReducer } from './reducers/page/pagemetaReducer';
import { accountsReducer } from './reducers/accounts/accountsReducer';

const reducer = {
  // Accounts
  accounts: accountsReducer,

  page: pagemetaReducer,
}

const store = configureStore({
  reducer: reducer
})

export default store;