import React from "react";
import { Route, Routes } from 'react-router-dom';
import Home from "../pages/Home";
import AlbumRoutes from "./AlbumRoutes";
import BlogRoutes from "./BlogRoutes";
import MusicRoutes from "./MusicRoutes";
import ProfileRoutes from "./ProfileRoutes";

const BrowserRoutes = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<Home />} />
            </Routes>
            <AlbumRoutes />
            <BlogRoutes />
            <MusicRoutes />
            <ProfileRoutes />
        </>
    )
}

export default BrowserRoutes;